@import 'ui-kit/variables';

$nav_width: 80px;
$panel-filter-width: 313px;
$header_height: 60px;
$right_panel_width: 340px;

$color_background_main: #f1f5f6;

$color_loading_overlay: rgba(33, 33, 33, 0.6);

$middle-blue: #2a4057;
$team-ligther-blue: #d8f0fc;
$toolbar_height: 37px;

$color_todo: #202739;
$color_done: #00c179;
$color_frozen: #ff0036;
$color_orange: #f4902f;
$color_notApplicable: #aaaaaa;

$color_exercise_active: $color_orange;

$color-arrow-carroussel: white;
$color-arrow-carroussel-bckgrd: rgba(63, 96, 133, 0.75);

$color_menu_background: #2d2d2d;
$color_check_list_background: #000000;
$color_menu_font: #ffffff;
$color_active_menu_item_background: #222222;

$color_box_title_crisis: #ff6166;
$color_short_info: #d4f0fa;
$color_phone_contact: #4daed1;

$color_active_filter: #2d2d2d;
$color_disable_filter: #dedede;

$color_background_announcement: #395f88;
$color_author_announcement: #bdbdbd;
$color_title_announcement: white;

$color_confirm_announcement: #f90013;
$color_participate_announcement: #00c279;

$color_function_affected: #3f84cf;
$color_function_nobody: #f0636c;
$color_function_noauth: #d1d1d1;

$modal-headers-color: #215e83;
$button-validate-background-color: #ed3b4b;
$button-validate-second-background-color: $modal-headers-color;

$color-user-modal-background: #f1f5f5;

$color-crew: #4a9693;

$color-chat-background: #e4e9ee;

$default-font-family: Nunito, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$title-font-family: AvenirNextLTPro-Cn, sans-serif;
$condensed-font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif;
$condensed-bold-font-family: Avenir Next Condensed Bold, Helvetica, Arial, sans-serif;
$condensed-semibold-font-family: Avenir Next Condensed Demi, Helvetica, Arial, sans-serif;
$condensed-medium-font-family: Avenir Next Condensed Medium, Helvetica, Arial, sans-serif;

@mixin title-font-style($fontSize) {
  font-family: $title-font-family !important;
  font-size: $fontSize !important;
}

@mixin new-title-font-style($fontSize) {
  font-family: $condensed-font-family !important;
  font-size: $fontSize !important;
  font-weight: 600 !important;
}

@mixin center-vertically-no-justify() {
  display: flex;
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;

  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-flex-align: center;
  -webkit-box-align: center;
}

@mixin flex-row-center() {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@mixin center-vertically() {
  @include center-vertically-no-justify();

  justify-content: center;
  -webkit-justify-content: center;
}

@mixin center-left-vertically {
  @include center-vertically-no-justify();

  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

@mixin center-right-vertically {
  @include center-vertically-no-justify();

  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

@mixin BoxShadowHelper($level: 1) {
  @if ($level == 1) {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  @if ($level == 2) {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  @if ($level == 3) {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  @if ($level == 4) {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  @if ($level == 5) {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

@mixin unfocus() {
  outline: 0 !important;
}

@mixin taskTitleLink() {
  a {
    border: 1px solid #e7e7e7;
    display: inline-block;
    padding: 4px 40px 2px 6px;
    background-image: url('/assets/images/external-link.svg');
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: center;
    background-size: 20px 20px;
    margin-right: 5px;
    margin-top: 8px;

    &:hover {
      background-color: white;
      border-color: #dddddd;
      text-decoration: underline;
    }

    &.ng-click-active {
      border-color: #dddddd;
      background-color: rgba(200, 200, 200, 0.8);
    }
  }
}

@mixin TagResultBox() {
  .result-box {
    border: 1px solid #e7e7e7;
    padding: 8px;
    background-color: white;
    background-image: url('/assets/images/right-arrow.svg');
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: center;
    background-size: 20px 20px;

    &:hover {
      background-color: white;
      border-color: #dddddd;
      text-decoration: underline;
    }

    &.ng-click-active {
      border-color: #dddddd;
      background-color: rgba(200, 200, 200, 0.8);
    }

    &.absent {
      background-color: #eeeeee;
      background-image: none;
      color: #9d9d9d;
      cursor: default;
      pointer-events: none;
    }
  }
}

@mixin ListFilter() {
  height: 100%;

  .btn-group {
    display: block;
  }
  .btn {
    text-align: left;
    background-color: $color_background_announcement;
    border-radius: 5px;
    border: none;
    font-weight: 100;
    width: 100%;
    //box-shadow: none;
    height: 36px;
    line-height: 36px;
    padding: 0 10px;

    .value {
      color: white;
      font-weight: 600;
      display: block;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
  .dropdown-menu {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    box-shadow: none;
    min-width: 0px;

    li {
      margin: 2px 0;
      min-width: 0px;
      height: 40px;

      &.active {
        a {
          background-color: $color_background_announcement;
        }
      }

      a {
        display: block;
        padding: 0px 10px;
        font-size: 12px;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        background-color: $color_menu_background;
        border-radius: 5px;
        height: 100%;
        cursor: pointer;
        line-height: 40px;

        &:hover {
          background-color: $color_disable_filter;
          color: $color_active_filter;
        }
      }
    }
  }
}

@mixin HistoriesItems() {
  list-style: none;
  margin: 0;
  padding: 0;

  li:not(.load-more) {
    color: black;
    padding: 7px 0;
    position: relative;
    background-color: white;
    margin-top: -1px;

    &.hol-clickable {
      margin: 2px 0;
    }

    &.notClickable {
      border: 1px solid $border-color;
    }

    &.item-infiniscroll {
      text-align: center;
      border: none;
      padding: 20px;
      background-color: transparent;
      transition: all 0s;
    }

    .wrapper {
      @include center-vertically-no-justify();
    }

    .moment,
    .title {
      display: inline-block;
    }

    $historyitem_col_first_width: 200px;
    $picto_width: 200px;

    .historyitem-col-first {
      width: $historyitem_col_first_width;
      min-width: $historyitem_col_first_width;
    }

    .member {
      width: 100%;
      text-align: center;
    }

    .at {
      text-transform: uppercase;
      text-align: center;
      color: #676767;
      margin: 2px 0;
    }

    .moment {
      margin-top: 3px;
      font-weight: 400;
      width: $historyitem_col_first_width;
      text-align: center;
      transition: color 1s ease-in;

      .wrapper {
        display: block;
        position: relative;

        .date {
          font-weight: 200;
          line-height: 14px;

          .the-date {
            font-size: 17px;
          }

          .the-time {
            font-size: 15px;
          }
        }
      }
    }

    .separator {
      background-color: black;
      width: 6px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: $historyitem_col_first_width;
      transition: background-color 1s ease-in;

      &.TODO {
        background-color: $color_orange;
      }

      &.DONE {
        background-color: $color_done;
      }

      &.FROZEN {
        background-color: $color_frozen;
      }

      &.NOT_APPLICABLE {
        background-color: $color_notApplicable;
      }
    }

    .status {
      display: none;
      position: relative;
      text-transform: uppercase;
      font-weight: bold;
      width: $historyitem_col_first_width;
      text-align: center;
      font-size: 0.7em;
      font-family: $default-font-family;
      margin-bottom: 13px;

      &.TODO {
        color: $color_orange;
      }

      &.DONE {
        color: $color_done;
      }

      &.FROZEN {
        color: $color_frozen;
      }

      &.NOT_APPLICABLE {
        color: $color_notApplicable;
      }
    }

    .title {
      position: relative;
      padding: 0 30px;
      width: 100%;
      font-weight: 400;
      font-size: 1.2em;

      .user-comment {
        white-space: pre;
        padding-left: 7px;
        position: relative;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .result {
      width: $picto_width;
      padding-right: 5px;

      @include TagResultBox();
    }
  }
  li:not(.hol-clickable) + li.hol-clickable {
    margin-top: 0;
  }
  .task-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 20px;
    line-height: 26px;
    overflow: hidden;
    height: 20px;
    padding: 8px 0;
    display: block;
    box-sizing: content-box;
  }
}

@mixin ListItemEnterFromLeft() {
  $ListItem_borderColor: #cecece;
  &.ng-enter-stagger {
    transition-delay: 0.1s;
    transition-duration: 0s;
  }
  &.ng-enter,
  &.ng-enter-prepare {
    transition: opacity, border-bottom-color, transform 0.5s ease;
    opacity: 0;
    transform: translateX(-100px);
    border-bottom-color: transparent;

    &.ng-enter-active {
      opacity: 1;
      transform: translateX(0);
      border-bottom-color: $ListItem_borderColor;
    }
  }
}

@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
}

@mixin hol-color-helper($color, $secondary-color: white) {
  color: $color !important;
  button.md-raised,
  &.full {
    background: $color !important;
    color: $secondary-color !important;
  }
}

@mixin filter-btn($filter-color: $primary-color, $filter-light-color: mix(white, $filter-color, 90%)) {
  border: 2px solid $filter-color;
  color: $filter-color;
  background: $filter-light-color;
  min-width: 0;
  min-height: 28px;
  margin-top: 0;
  box-shadow: none;
  line-height: 12px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    background: $filter-light-color;
    color: $filter-color;
  }
  &.active {
    background: $filter-color;
    color: white;

    &:hover,
    &:focus {
      background: $filter-color;
      color: white;
    }
  }
}

@mixin inputLikeTrello($with, $text-align) {
  width: $with;
  text-align: $text-align;
  border: none;
  background: transparent;
}

@mixin scrollWithShadow($min-height: auto, $max-height: $min-height, $height: $min-height) {
  background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
  overflow-y: scroll;
  min-height: $min-height;
  height: $height;
  max-height: $max-height;
}

@mixin colorButton($backgroundColor, $color: white) {
  &,
  &:not([disabled]):hover,
  &:not([disabled]).md-focused {
    color: $color !important;
    background-color: $backgroundColor !important;
  }
  &:not([disabled]):hover {
    background-color: darken($backgroundColor, 10%) !important;
  }
}
