@import './variables';

.hol-warn {
  color: $hol-warn !important;
}
.hol-accent {
  color: $hol-accent;
}
.hol-success {
  color: $hol-success !important;
}
.hol-info {
  color: $hol-primary;
}

.font14 {
  font-size: 14px;
  font-family: Nunito, Helvetica, Arial, sans-serif;
}
