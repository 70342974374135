@import '../ui-kit/variables';
@import '../mixins';
@import '../crew/crew-variables';

.crew-todo-box {
  .iscroll-scroller {
    ul.hol-list {
      height: 60vh;
      max-height: 60vh;
      overflow: scroll;
    }
  }

  .hol-box__header {
    justify-content: normal;

    .title {
      flex: 1;

      .icon {
        padding: 3px 2px 2px 2px;
      }
    }

    .filters-container {
      display: flex;
      padding-top: 3%;
      padding-bottom: 3%;
      align-items: center;

      .filter-container {
        button {
          min-width: 0;
          margin-top: 0;
          min-height: 28px;
          text-overflow: ellipsis;
          max-width: 150px;
          overflow: hidden;

          span:not(.caret) {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .hol-search-box {
        input {
          max-width: 120px;
        }
      }
    }
  }

  .box_add_button {
    width: 100%;
    margin: 12px 0;
  }

  .add-button {
    font-size: 16px;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    text-overflow: ellipsis;
  }

  .paddingbutton {
    width: fit-content;
    padding: 8px 12px;
  }

  .collapse-container {
    height: 2em;

    .btn-collapse {
      color: $primary-color;
      background: transparent;

      i {
        transform: rotateZ(180deg);
      }

      &.collapsed {
        i {
          transform: rotateZ(0deg);
        }
      }
    }
  }

  .hol-box__content .iscroll-wrapper {
    left: 0;
    right: 0;
    bottom: 0;
  }

  .main-content {
    display: flex;
    flex-direction: column;
  }
}

.todo-filter-dropdown-menu-mobile {
  right: 0 !important;

  li {
    a {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
