@import './variables';
@import 'src/styles/mixins';

@media only screen and (max-width: 959.99px) {
  #main {
    position: absolute;
    left: 0;
    margin-bottom: 0;
    padding: 0;
    .hol-row {
      overflow: auto;
      margin-left: 0;
      margin-right: 0;
      padding: 5px 0;
      > .col-xs-12 {
        padding: 0;
        margin-bottom: $content-padding;
        height: auto;
      }
    }
    .hol-page-content {
      padding-left: 80px;
    }
    mat-accordion {
      mat-expansion-panel {
        padding: $hol-content-padding;
        margin-bottom: $hol-content-padding;
        height: auto;
        .mat-expansion-panel-header {
          padding: 0;
          &:hover {
            background: none;
          }
          .mat-expansion-panel-header-title {
            @include new-title-font-style(20px);
            align-items: center;

            button {
              margin-left: 8px;
            }
            span:not(.mat-button-wrapper) {
              margin-left: 4px;
              &.hol-items-count {
                color: $hol-dark-grey;
                font-size: 14px;
              }
            }
            .mat-mini-fab .mat-button-wrapper {
              line-height: 20px;
            }
          }
        }
      }
    }
    .hol-search-container {
      height: $hol-input-height !important;
      margin: $hol-content-padding;
      max-width: 100%;
    }
    .hol-box {
      .hol-box__content .iscroll-wrapper {
        position: relative;
        top: initial !important;
        left: initial;
        right: initial;
        bottom: initial;
      }
      &.hol-box-filter-panel {
        overflow: auto !important;
        margin-left: 0;
        margin-right: 0;
        padding: 5px 0;
        display: block;
        > .hol-box-filter-panel-container {
          padding: 0;
          margin-bottom: $content-padding;
          height: auto;
        }
      }
    }
    .status-decision-container {
      .history-title {
        font-weight: bold;
        color: $primary-color;
        margin-bottom: $content-padding;
      }
    }
  }
  .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-dialog-container .hol-dialog-wrapper {
    min-width: 100%;
    max-width: 100%;
  }
  .clickable.ng-click-active {
    opacity: 0.7;
  }
  .authentication-section {
    margin: 0 $hol-content-padding;
    .access-code-form {
      width: auto !important;
      flex-wrap: wrap;
      align-content: center;
      flex-direction: column;
      > button {
        margin: $hol-text-padding 0;
      }
    }
  }

  .md-editor-container {
    .md-layout {
      .editor-container {
        flex-wrap: wrap;
        > div {
          flex: 1 1 auto !important;
          min-height: 200px;
          width: 100%;
        }
      }
    }
  }
}
