@use 'sass:math';
@import '../ui-kit/variables';
@import '../mixins';

.hol-menu-panel {
  margin: 0 0 2px 0 !important;
  border-radius: 0 !important;
  background: $text-color--light;
  .panel-heading {
    text-align: left;
    text-transform: initial;
    padding: 0;
    .panel-title {
      font-size: 15px;
      .hol-menu-panel-title {
        padding: $content-padding math.div($content-padding, 2);
        @include center-vertically();
        justify-content: space-between;
        font-weight: bold;
      }
      a {
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
    .hol-menu-panel-icon {
      position: absolute;
      right: 0;
      font-size: 16px;
      font-weight: bold;
      transform: rotateZ(0deg);
    }
  }
  .panel-body {
    padding: 0;
    border-top: none !important;
  }
  &.panel-open {
    .hol-menu-panel-icon.arrow {
      transform: rotateZ(90deg);
    }
  }
}
