@import '../ui-kit/variables';
@import '../mixins';

.flights-status-graph {
  &__zone {
    &:nth-child(1) {
      fill: $lowest-criticity-color;
    }
    &:nth-child(2) {
      fill: $low-criticity-color;
    }
    &:nth-child(3) {
      fill: $medium-criticity-color;
    }
    &:nth-child(4) {
      fill: $high-criticity-color;
    }
    &:nth-child(5) {
      fill: $highest-criticity-color;
    }
  }

  &__line {
    stroke-width: 1px;
    stroke: $primary-color;
    &-outer {
      stroke-width: 3px;
      stroke: white;
    }
  }
  &__point {
    fill: white;
    stroke: black;
    stroke-width: 1px;
  }
  &__axis {
    font-family: $condensed-font-family;
    font-size: 13px;
  }
}
