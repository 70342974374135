@import 'variables';

.hol-duty-indicator {
  margin-right: $content-padding;
  cursor: pointer;
  display: inline-block;
  content: ' ';
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.online {
    background-color: $online-color;
  }
  &.available-offline {
    background-color: $available-offline-color;
  }
  &.offline {
    background-color: $offline-color;
  }
}
