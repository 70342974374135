@import 'variables';

.hol-panel-md {
  @extend .hol-clickable;
  .panel-heading {
    border-radius: $border-radius;
    padding: 0;
    .panel-title {
      text-transform: none;
      a {
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
  &.panel-open .panel-heading {
    background-color: $primary-color--dark;
    color: white;
    border-radius: $border-radius $border-radius 0 0;
  }
}

.hol-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: solid 1px $primary-color;
  margin-bottom: $content-padding;
  border-radius: 4px;
  &__header {
    padding: 10px;
    display: flex;
    background: $primary-color;
    color: white;
  }
  &__content {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
}
