@use 'sass:math';
@import '../ui-kit/variables';
@import '../mixins';

.md-sidenav-left {
  .header {
    height: 60px;

    .company-logo {
      margin-left: 2.4 * $content-padding;
      margin-top: $content-padding;
      margin-bottom: math.div($content-padding, 2);
      height: 60px;
    }

    .close-button {
      position: absolute;
      top: 2 * $content-padding;
      right: $content-padding;
      color: $primary-color;
      padding: 0;

      &:hover {
        background-color: #cccccc;
      }

      .mat-icon {
        @include md-icon-size(40px);
      }
    }
  }

  .line {
    background: #d8d8d8;
    width: 85%;
    height: 1px;
    margin: 0 auto;
  }

  .vertical-menu {
    padding: 1.2 * $content-padding 0;

    a,
    span {
      display: block; /* Make the links appear below each other */
      padding: 1.2 * $content-padding 4.8 * $content-padding; /* Add some padding */
      text-decoration: none; /* Remove underline from links */
      font-family: $condensed-font-family;
      font-size: 22px;
      height: 4.8 * $content-padding;

      &.is-active {
        font-weight: 600;
      }

      &.vertival-menu-with-submenu {
        &::after {
          content: '❯';
          right: 4.8 * $content-padding;
          position: absolute;
        }

        &.opened {
          &::after {
            transform: rotate(90deg);
          }
        }
      }
    }

    .vertical-submenu {
      a {
        font-size: 14px;
        padding: 0.5 * $content-padding 3 * $content-padding;
        height: auto;

        &::before {
          content: '- ';
        }
      }
    }
  }

  .menu-occ {
    color: $occ-color;
  }

  .menu-ecl {
    color: $ecl-color;
  }

  .menu-ops {
    color: $ops-color;
  }

  .menu-crew {
    color: $crew-color;
  }

  .menu-mcc {
    color: $mcc-color;
  }

  a:hover {
    background: #bdbdbd; /* Dark grey background on mouse-over */
  }

  a.active {
    background-color: #4caf50; /* Add a green color to the "active/current" link */
    color: white;
  }

  .vertical-menu-settings a {
    display: flex; /* Make the links appear below each other */
    align-items: center;
    padding: 1.4 * $content-padding 4.8 * $content-padding; /* Add some padding */
    text-decoration: none; /* Remove underline from links */
    font-family: $condensed-font-family;
    font-weight: lighter;
    font-size: 13px;
    color: #2d2d2d;
    height: 4.3 * $content-padding;

    i,
    img {
      position: absolute;
      right: 4 * $content-padding;

      &.managers {
        right: 3.3 * $content-padding;
      }
    }
  }
}

md-sidenav,
md-backdrop {
  position: fixed;
}

md-sidenav {
  z-index: 9999;
}

.isOpen {
  overflow: hidden;
}
