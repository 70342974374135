@import '../mixins';
@import '../ui-kit/variables';

.button-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.to-right {
    justify-content: flex-end;
  }
}

.file-upload-btn-container {
  display: inline-block;
}

.uploadedFile {
  border: 1px solid #dddddd;
  padding: 8px 16px;
  display: inline-block;

  .deleteButton {
    display: inline-block;
    i {
      @include center-vertically();
      width: 40px;
      height: 40px;
      color: #ed3b4b;
      font-size: 2em;
    }
  }
}

a.external-document {
  border: 1px solid #e7e7e7;
  padding: 8px;
  background-color: white;
  background-image: url(/assets/images/right-arrow.svg);
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: center;
  background-size: 20px 20px;
  display: block;
  float: right;
  font-weight: 400;
  padding-right: 25px;
}

a.external-attachment {
  .icon:before {
    color: $primary-color--dark;
  }
}

.file-image-display-full {
  display: flex;
  a.external-attachment {
    figure {
      text-align: center;
      overflow: hidden;
      position: relative;
      img {
        max-height: 50vh;
        max-width: 100%;
      }
      figcaption {
        background-color: black;
        color: white;
        transition: 0.3s;
        transform: translateY(0);
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        .icon:before {
          color: white;
        }
      }
      &:hover figcaption {
        opacity: 1;
        transform: translateY(-100%);
      }
    }
  }
}

.row.has-attachments {
  margin-top: -10px;
  .file-upload:last-child button {
    margin-right: 0;
  }
}

.file-upload-btn-container {
  &.no-text {
    button {
      width: 40px;
      min-width: 40px;
      padding: 0 !important;
      md-icon {
        margin-right: 0 !important;
      }
    }
  }
}
