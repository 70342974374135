@use 'sass:math';
@import '../ui-kit/variables';
@import '../mixins';
@import '../crew/crew-variables';

.crew-event-line-color {
  @for $i from 1 through length($filters-colors) {
    $filter-colors: nth($filters-colors, $i);
    &.filter-color-#{$i - 1} {
      .line-color {
        height: 2px;
        margin: $content-padding 0px;
        background-color: nth($filter-colors, 1);
      }
    }
  }
}

.crew-event-item {
  padding: $content-padding;
  font-family: $condensed-font-family;
  background-color: $background-secondary;
  width: 100%;

  @for $i from 1 through length($filters-colors) {
    $filter-colors: nth($filters-colors, $i);
    &.filter-color-#{$i - 1} {
      .event-item__details {
        border-color: nth($filter-colors, 1);
      }
    }
  }

  .event-item__title {
    @extend .hol-spaced-line;
    white-space: nowrap;
    flex-wrap: wrap;

    .flight-number {
      color: $text-color;
    }

    .text-right {
      flex: 1;
      margin-left: math.div($content-padding, 2);
    }

    .text-center {
      align-self: center;
    }
  }

  .event-item__details {
    border-top: 2px solid $border-color;
    margin-top: 5px;
    padding-top: 10px;
    font-weight: normal;
    flex-wrap: wrap;
  }

  .almost-elapsed {
    font-weight: bold;
    color: $warning-color;
  }

  .elapsed {
    font-weight: bold;
    color: $error-color;
  }

  .last-info {
    text-align: right;
    color: $text-color--light;
    margin-bottom: -5px;
  }
}
