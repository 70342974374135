@import '../ui-kit/variables';
@import '../mixins';

#page-occ-history {
  .history-box {
    .hol-box__header {
      .form-group {
        display: inline-block;
        width: 190px;
        vertical-align: middle;
        margin-bottom: 0;
      }
    }

    .occ-table {
      .iscroll-wrapper {
        top: 30px !important;
      }
    }
    .log-nowrap {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .log-muted {
      font-size: 0.9em;
      color: $text-color--light;
    }
    .histories {
      margin-top: 10px;
      font-family: $condensed-font-family;
      > li {
        padding: 0;
        font-weight: initial;
        border-top: 2px solid darken($border-color, 10%);
        &.new-day-line {
          border-top-width: 20px;
          .time {
            margin-top: -20px;
          }
        }
      }
    }
    .time-slot {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      .time {
        min-width: 85px;
        margin-top: -10px;
        background: white;
      }
      .logs {
        flex: 1;
        margin-left: $content-padding;
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 80px);
        .new-supervisor {
          width: 100%;
          background: $color_function_affected;
          margin-top: 0;
          padding-top: 3px;
          color: white;
          font-weight: bold;
          &.empty-supervisor {
            background-color: $error-color;
          }
          i.fa {
            height: 16px;
            font-size: 0.9em;
          }
        }
        .new-day {
          width: 100%;
          padding-top: 3px;
          color: white;
          font-weight: bold;
          margin-top: -22px;
          i.fa {
            height: 16px;
            font-size: 0.9em;
          }
        }
        .col-decision,
        .col-event,
        .col-logbook {
          width: calc((100% - 10px) / 3);
          display: flex;
          justify-content: stretch;
          align-items: center;
          border-left: 1px solid $border-color;
          .empty-log {
            margin: $content-padding 0;
            flex: 1;
            text-align: center;
          }
          .hol-list {
            flex: 1;
            height: 100%;
            width: 100%;
            li {
              padding: $content-padding;
              display: flex;
              justify-content: stretch;
              align-items: flex-start;
              .time {
                margin-top: 0;
                .status .full {
                  border-radius: 3px;
                  display: block;
                  text-align: center;
                  padding-top: 2px;
                }
                .attachments {
                  margin-top: 5px;
                }
              }
              .comment {
                margin-left: $content-padding;
                max-width: calc(100% - 45px);
                overflow: hidden;
                text-overflow: ellipsis;
                .event-title {
                  color: $primary-color;
                }
              }
            }
            li:first-child {
              border-top: none;
            }
          }
        }
      }
      &.header {
        .time {
          margin-top: 0;
          text-align: center;
        }
        .col-decision,
        .col-event,
        .col-logbook {
          border-left: none;
          justify-content: center;
        }
      }
    }

    .long-status {
      padding: 5px 5px 0px 5px;
    }
  }
}
