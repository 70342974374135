.mat-menu-panel.indicator-menu {
  .mat-menu-content {
    padding: 5px 5px;
  }

  .mat-menu-item {
    padding: 0 3px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
