@import '../ui-kit/variables';

md-dialog.md-content-overflow .md-actions,
md-dialog.md-content-overflow md-dialog-actions {
  border-top-width: 0px;
  padding-top: $content-padding;
}

.md-dialog-container .md-dialog-container {
  animation: backdropAnim 0.45s;
  animation-fill-mode: forwards;
}

@keyframes backdropAnim {
  from {
    background: transparent;
  }
  to {
    background: rgba(33, 33, 33, 0.48);
  }
}
