@import '../mixins';

.md-dialog-container {
  md-dialog.changeUserStatusModal {
    width: 300px;
    min-width: 300px;
    font-family: $condensed-font-family;

    .dialog-content {
      text-align: center;
      font-size: 1.15em;

      p {
        margin: 0;
      }

      .an-half:first-child,
      .an-half-forced {
        margin-bottom: 34px;
      }

      .help {
        font-size: 0.8em;
        font-style: italic;
      }

      .md-button {
        width: 100%;
        margin: 6px 0;
      }
    }

    .dialog-actions {
      font-size: 1.15em;
      justify-content: space-between;
    }
  }
}
