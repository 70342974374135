@import '../../styles/ui-kit/variables';

.mat-tab-group {
  height: 100%;
}

.mat-tab-body-wrapper {
  height: 100%;
}

.mat-tab-label-active {
  background: $primary-color;
  color: $background-secondary;
  opacity: 1 !important;
}
