@import 'variables';
@import '../mixins';

.hol-clickable {
  cursor: pointer;
  border-radius: $border-radius;
  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 4px rgba(23, 23, 23, 0.2);
  border: none;
  transition: box-shadow 0.2s, opacity 0.2s;
  &.ng-leave {
    // No transition on ng-leave to avoid glitch
    transition: none;
  }
  &:hover {
    box-shadow: 0px 1px 4px rgba(23, 23, 23, 0.3);
  }
  &.ng-click-active {
    box-shadow: 0px 1px 4px rgba(23, 23, 23, 0.3);
    opacity: 0.7;
  }
  &[disabled] {
    pointer-events: none;
    box-shadow: none;
    border: 1px solid $border-color;
  }
}

.hol-animate-enter-left {
  &.ng-enter-stagger {
    transition-delay: 0.02s;
    transition-duration: 0s;
  }
  &.ng-enter,
  &.ng-enter-prepare {
    transition: transform 0.3s ease;
    transform: translateX(-100%);
    &.ng-enter-active {
      transform: translateX(0);
    }
  }
}

.hol-animate-leave-right {
  &.ng-leave-stagger {
    transition-delay: 0.02s;
    transition-duration: 0s;
  }
  &.ng-leave {
    transition: transform 0.3s ease;
    transform: translateX(0);
    &.ng-leave-active {
      transform: translateX(100%);
    }
  }
}

.hol-animate-pop {
  animation: pop 0.3s cubic-bezier(0.1, 0.7, 0.74, 1.3);
}

@keyframes pop {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.hol-primary {
  @include hol-color-helper($primary-color);
}

.hol-crisis {
  @include hol-color-helper($crisis-color);
}

.hol-training {
  @include hol-color-helper($training-color);
}

.hol-occ {
  @include hol-color-helper($occ-color);
}

.hol-ecl {
  @include hol-color-helper($ecl-color);
}

.hol-ops {
  @include hol-color-helper($ops-color);
}

.hol-crew {
  @include hol-color-helper($crew-color);
}

.hol-mcc {
  @include hol-color-helper($mcc-color);
}

.hol-goc {
  @include hol-color-helper($goc-color);
}

.hol-todo {
  @include hol-color-helper($todo-color);
}

.hol-done {
  @include hol-color-helper($done-color);
}
.hol-archive {
  @include hol-color-helper($deactivate-color);
}

.hol-online {
  @include hol-color-helper($online-color);
}

.hol-frozen {
  @include hol-color-helper($frozen-color);
}

.hol-offline {
  @include hol-color-helper($offline-color);
}

.hol-error {
  @include hol-color-helper($error-color);
}

.hol-warning {
  @include hol-color-helper($warning-color);
}

.hol-backtodo {
  @include hol-color-helper($backtodo-color);
}

.hol-event {
  @include hol-color-helper($event-color);
}
.hol-available-offline {
  @include hol-color-helper($available-offline-color);
}

.hol-notapplicable {
  @include hol-color-helper($color_notApplicable);
}

.hol-affected {
  @include hol-color-helper($color_function_affected);
}

.hol-validate {
  @include hol-color-helper($validate-color);
}

.hol-padding {
  padding: $content-padding;
}

.hol-margin {
  margin: $content-padding;
}

.hol-no-padding {
  padding: 0 !important;
}

.hol-no-margin {
  margin: 0 !important;
}

.hol-pre {
  white-space: pre-line;
}
