.mat-form-field.mat-form-field-should-float.no-label .mat-form-field-label {
  display: none;
}

mat-form-field {
  &.full-width {
    width: 100%;
  }
  &.description-form-field {
    font-weight: bold;
    font-size: 1.5em;
    textarea {
      min-height: 27px;
    }
  }

  &.risk {
    .mat-form-field-wrapper {
      margin-right: 30px;
    }
  }

  &.scenario {
    min-width: 300px;
  }
}
