@import '@angular/material/theming';

$hol-black: #171717;
$hol-dark_grey: #848484;
$hol-grey: #c9c9c9;
$hol-light-grey: #ededed;
$hol-lighter-grey: #fafafa;

$md-hol-primary2: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6ac0,
  500: #3f50b5,
  600: #3948ab,
  700: #303e9f,
  800: #283493,
  900: #1a237e,
  A100: #f8f8f8,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $hol-dark_grey,
    100: $hol-dark_grey,
    200: $hol-dark_grey,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: $hol-dark_grey,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$md-hol-accent2: (
  50: #fdede5,
  100: #fadccc,
  200: #f8cab2,
  300: #f5b999,
  400: #f2a87f,
  500: #ed854c,
  600: #eb7433,
  700: #e8621c,
  800: #e65200,
  900: #c94801,
  A100: #ffffff,
  A200: #fff2f3,
  A400: #ffbfc2,
  A700: #ffa6aa,
  contrast: (
    50: $hol-dark_grey,
    100: $hol-dark_grey,
    200: $hol-dark_grey,
    300: $hol-dark_grey,
    400: $hol-dark_grey,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: $hol-dark_grey,
    A200: $hol-dark_grey,
    A400: $hol-dark_grey,
    A700: $hol-dark_grey,
  ),
);
$md-holis-warn2: (
  50: #ffeaec,
  100: #ffcace,
  200: #f29692,
  300: #e86c68,
  400: #f14941,
  500: #f53621,
  600: #e72a22,
  700: #d51d1d,
  800: #c81215,
  900: #ba0005,
  A100: #ffdadb,
  A200: #ff7476,
  A400: #fd1014,
  A700: #e50e12,
  contrast: (
    50: $hol-dark_grey,
    100: $hol-dark_grey,
    200: $hol-dark_grey,
    300: $hol-dark_grey,
    400: $hol-dark_grey,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: $hol-dark_grey,
    A200: $hol-dark_grey,
    A400: $hol-dark_grey,
    A700: $hol-dark_grey,
  ),
);

$hol-primary: mat-color($md-hol-primary2, 500);
$hol-blue: mat-color($md-hol-primary2, 900);
$hol-light-blue: mat-color($md-hol-primary2, 50);
$hol-accent: mat-color($md-hol-accent2, 700);
$hol-warn: mat-color($md-holis-warn2, 700);
$hol-success: #58960e;
$hol-error: $hol-warn;

// Sizes && SPACING
$border-radius: 4px;
$hol-content-padding: 16px;
$hol-text-padding: 6px;
$hol-content-box: 8px;
$hol-item-margin: 24px;
$hol-input-height: 42px;
