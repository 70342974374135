@import 'src/styles/ui-kit/variables';

ngx-hm-carousel.hol-carousel {
  .contentCarousel {
    display: flex;

    .item {
      width: 100%;
      padding: 0.5em;
      display: block;
      opacity: 0.5;
      transition: all 0.3s ease-in-out !important;

      // using delay to prevent left change show error
      &.fade_animation {
        transition: all 0.3s ease-in-out !important;
      }

      &.visible {
        opacity: 1;
      }

      .img {
        width: 100%;
        height: 400px;
        display: block;
        background-size: cover;
        background-position: center;
      }
    }
  }

  .transition {
    transition: all 0.3s ease-in-out !important;
  }

  /*
  [ngx-hm-carousel-container] {
    display: flex;
    padding-bottom: 4 * $content-padding;
  }
  */

  .ball {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    border: 1px solid $primary-color;

    &.selected {
      background: $primary-color;
    }
  }

  .direction {
    opacity: 0;
  }

  &:hover {
    .direction {
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }
  }
}
