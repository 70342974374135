@use 'sass:math';
@import '../mixins';

.header-component {
  background: white;
  border-bottom: 1px solid $border-color;
  height: $header_height;

  .menu-icon {
    color: #3f6185;
  }

  .content-group {
    flex: 1; // fill available space
    display: flex;

    .mcc-filters-container {
      display: flex;
      align-items: center;
    }

    .mcc-text {
      color: $mcc-color;
      font-weight: bold;
      text-align: center;
      font-size: 26px;
      margin-left: $content-padding;
    }

    .crew-text {
      color: $crew-color;
      font-weight: bold;
      text-align: center;
      font-size: 26px;
    }

    .occ-text {
      color: $occ-color;
      font-weight: bold;
      text-align: center;
      font-size: 26px;
      padding-right: $content-padding;
    }

    .ecl-text {
      color: $ecl-color;
      font-weight: bold;
      text-align: center;
      font-size: 26px;
      padding-right: $content-padding;
    }

    .crisis-text {
      font-weight: bold;
      text-align: center;
      font-size: 26px;
      padding-right: $content-padding;
    }
  }

  button.hamburger-button {
    border: none;
    background: white;

    &:hover {
      /* Darker background on mouse-over */
      background: white;
    }
  }

  .user-group {
    display: flex;
    align-self: stretch;
    color: $text-color;

    button.header-button {
      padding: 0 $content-padding;
      background: transparent;
      border-width: 0 0 0 1px;
      border-color: $border-color;
      &:hover {
        /* Darker background on mouse-over */
        background: $border-color;
      }

      &.button-logout {
        max-width: 160px;
        @extend .hol-spaced-line;

        .user-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: math.div($content-padding, 2);
        }
      }
    }
  }
}

.breaking-news-container {
  background: $crisis-color;
  color: white;
  font-weight: bold;
  padding: 5px 0;
  width: 100%;
  display: block;

  .news {
    white-space: nowrap;

    .separator {
      margin: 0 $content-padding;
    }
  }
}

.img-icon {
  height: 25px;
  width: 25px;
}
