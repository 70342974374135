@import '../mixins';

$addNewsButton_backgroundColor: #1f9ed9;
$breakingNewsItem_backgroundColor: white;
$breakingNewsItem_fontColor: black;
$breakingNewsItem_borderColor: #cecece;
$breakingNewsItem_pressedHoverColor: $breakingNewsItem_borderColor;
$validateButton_color: #ed3b4b;
$deleteButton_color: $validateButton_color;

.breakingNewsModal {
  .wrapper {
    @include center-vertically();
  }

  .breakingNews {
    list-style: none;
    margin: 0;
    padding: 0;

    li.news-item {
      padding: 0;
      border-bottom: 1px solid $breakingNewsItem_borderColor;
      position: relative;

      @include ListItemEnterFromLeft();

      transition: background-color 0.2s ease-in;

      &.ng-click-active,
      &.clickable:hover {
        background-color: $breakingNewsItem_pressedHoverColor;
        opacity: 1 !important;
      }

      .news,
      .deleteButton {
        display: inline-block;
      }

      $deleteButton_width: 60px;
      $deleteButton_height: 60px;

      .news {
        width: calc(100% - #{$deleteButton_width});
        color: $breakingNewsItem_fontColor;
        font-weight: 400;

        .news-text {
          padding: 20px 10px;
        }
      }

      .deleteButton {
        i {
          @include center-vertically();
          width: $deleteButton_width;
          height: $deleteButton_height;
          color: $deleteButton_color;
          font-size: 2em;
        }
      }

      &.item-infiniscroll {
        text-align: center;
        border: none;
        padding: 20px;
        background-color: transparent;
      }
    }
  }

  .news-edit {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .buttons {
    text-align: center;
    margin-top: 7px;
  }

  .form-group.add {
    padding: 20px;
    margin-top: 20px;
  }
}
