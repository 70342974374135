[ui-view] {
  @keyframes view_enter_animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &.ng-enter {
    opacity: 0;
    animation: 0.5s view_enter_animation;
  }
}
