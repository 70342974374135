mat-expansion-panel {
  mat-expansion-panel-header {
    &.event-group-header {
      .mat-expansion-indicator {
        display: none;
        color: transparent;
        background: transparent;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

@media (max-width: 800px) {
  .hol-mobile {
    mat-expansion-panel .mat-expansion-panel-body {
      padding: 0;
      overflow: scroll;
      height: calc(100vh - 250px);
    }
  }
}
