@import '../ui-kit/variables';
@import '../mixins';

.modal-markdown-editor {
  .dialog-content {
    textarea {
      height: 400px;
      min-height: 400px;
    }
  }
}
