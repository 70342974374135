@import '../mixins';
@import 'src/styles/new-ui-kit/variables';

.md-dialog-container {
  z-index: 999;
}

.uib-datepicker-popup {
  z-index: 1001;
}

.full-dialog {
  left: $nav_width;
  right: 0;
  top: 10%;
  bottom: 10%;
  margin: auto;
  position: absolute !important;
  max-width: 1024px !important;

  .mat-dialog-content {
    height: 100%;
  }
}

.hol-modal {
  .mat-dialog-content {
    min-height: 65vh !important;
    max-height: 65vh !important;
  }
}

.confirmation-modal {
  .mat-dialog-content {
    min-width: 30vw !important;
    max-width: 50vw !important;
    min-height: 20vh !important;
    max-height: 65vh !important;
  }
}

.spacer {
  flex: 1;
}

.mat-dialog-container {
  padding: 0 !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 95vh !important;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);

  .hol-dialog-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column nowrap;

    &.hol-modal {
      min-width: 60vw;
    }

    .mat-toolbar {
      box-shadow: none !important;
      padding: 16px 24px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;

      &-alt {
        background: #ffffff;
        box-shadow: none;
        padding: 16px 24px 16px 44px;
      }

      .mat-dialog-title {
        font-family: $condensed-font-family;
        font-size: 20px;
        font-weight: bold;
        margin: 3px 0 0 0;
        white-space: normal;
      }

      i {
        margin-right: 10px;
      }

      [mat-dialog-close] {
        color: $primary-color;
      }
    }
  }
}

mat-dialog-container .file-and-image-upload figure img {
  max-width: 600px;
}

.mat-dialog-content {
  margin: 0;
  padding: 20px;
  overflow: auto;

  .content-grid {
    padding: 20px;
  }

  /*
    .content-grid-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }


  .section {
    width: 100%;
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 4px;
    padding-top: 8px;

    .title {
      font-family: Nunito, Helvetica, Arial, sans-serif;
      font-size: 1.7rem;
      font-weight: 600;
      color: #094882;
    }

    .icon {
      color: #094882;
    }

    .content {
      flex-grow: 1; // Prends toute la place disponible.
      flex-shrink: 1;
      display: flex;
      align-items: stretch;
      justify-content: start;
    }
  }

  .content-grid {
    display: grid;
    grid-template-columns: 5fr 2fr;
    //  grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0px;

    .content-grid-button {
      padding: 8px;
      display: flex;
      flex-flow: column;
      gap: 4px;
      align-items: stretch;
      justify-content: start;
    }

    .hol-modal-title-area {
      font-size: 1.825rem;
      font-weight: 700;
      font-family: Nunito, Helvetica, Arial, sans-serif;
    }
  }
  */
}

.mat-toolbar {
  .acl-full-width {
    line-height: 20px !important;
  }
}

.mat-dialog-title {
  flex-grow: 1;
  flex-shrink: 0;
}

.action-bar {
  width: 100%;
  display: flex;
  flex-flow: row;
  gap: 8px;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 20px;
}

.action-bar,
.content-grid-button {
  button {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 0;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0 !important;
    border-style: none;
    padding: 4px 6px;
    background-color: #2f689d;

    color: white;

    &:hover {
      background-color: #478ac8;
    }

    :last-child {
      text-align: start;
    }

    &.action-inverted {
      background-color: white;
      color: #2f689d;
      border-color: #2f689d;
      border-style: solid;
      border-width: 1px;

      &:hover {
        background-color: #f6f8ff;
      }

      &:disabled {
        color: #4e82ae;
        background-color: #bac5d4;
      }
    }

    &.action-validate {
      background-color: #49945a;
      color: white;
      border-color: #49945a;

      &:hover {
        background-color: #418551;
      }

      &:disabled {
        color: #49945a;
        background-color: #bad4be;
      }
    }

    &.action-refuse {
      background-color: #da615c;
      color: white;

      &:hover {
        background-color: #c45752;
      }
    }

    &.action-refresh {
      background-color: #9155d9;
      color: white;

      &:hover {
        background-color: #824cc3;
      }
    }

    .iconsize {
      font-size: 20px;
    }

    &.toggled {
      background-color: #00bf56;
    }

    &.activated {
      opacity: 0.3;
    }
  }
}

.mat-dialog-actions {
  margin: 0 !important;
  padding: 16px 24px !important;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.2) !important;
  z-index: 1;
}
