@import 'src/styles/new-ui-kit/variables';

mat-button-toggle,
button {
  &.indicator-button {
    $indicator-colors: (
      $highest-criticity-color,
      $high-criticity-color,
      $medium-criticity-color,
      $lowest-criticity-color,
      $no-info-criticity-color
    );

    height: 25px;
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 11px;

    color: nth($indicator-colors, 5);
    background-color: white;
    border: 1px solid nth($indicator-colors, 5);
    border-radius: 4px;

    font-weight: bolder;

    .mat-button-toggle-label-content {
      padding: 0 3px;
    }

    @for $i from 1 through length($indicator-colors) {
      &.indicator-color-#{$i - 1} {
        background-color: nth($indicator-colors, $i);
        border: 1px solid nth($indicator-colors, $i);
        color: white;
      }
      &.indicator-color-invert-#{$i - 1} {
        background-color: white;
        border: 1px solid nth($indicator-colors, $i);
        color: nth($indicator-colors, $i);
      }
    }
  }
}

button.add-button {
  transform: scale(0.6);
}

button.mat-stroked-button {
  &:not([disabled]) {
    border-color: $hol-grey;
  }
}

button.mat-button,
button.mat-raised-button {
  &.hol-archive-btn {
    color: grey !important;
  }

  &.hol-validate-btn {
    background-color: $done-color;
    color: white;
    border-radius: 60px;
  }

  &.hol-validate-btn-secondary {
    background-color: $done-color;
    color: white;
  }

  &.hol-archive-btn-secondary {
    background-color: $hol-warn;
    color: white;
    border-radius: 2px;
    box-shadow: none;
  }

  &.hol-duplicate-btn {
    background-color: $hol-blue;
    color: white;
    border-radius: 2px;
    box-shadow: none;
  }

  &.hol-cancel {
    @include colorButton($hol-error);
  }

  &.hol-attachment-btn {
    &.has-content {
      color: $hol-blue;
      background-color: #ffffff;
      border: 1px solid $hol-blue;
    }

    background-color: $hol-blue;
    color: #ffffff;
    min-width: 40px;
    padding: 0px 10px;

    .mat-button-wrapper {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .btn-label {
      margin-left: 4px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon-quit {
      margin-left: 4px;
    }
  }

  &.rounded-icon-btn {
    min-width: 40px;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .mat-button-wrapper {
      border-radius: 50%;
      border-style: solid;
      border-width: 1px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      > * {
        vertical-align: baseline;
      }
    }
  }
}

button {
  &.hol-wide {
    width: 100%;
  }

  &.hol-rounded-btn {
    border-radius: 60px;
  }
}

mat-dialog-actions .mat-button-base + .mat-button-base {
  margin-left: 0px !important;
}

mat-button-toggle-group {
  &.tab-buttons {
    height: 30px;
    text-align: center;
    vertical-align: middle;
    margin: 0 $content-padding;
    align-items: center;
    font-size: 11pt;
    line-height: 11pt;

    mat-button-toggle {
      min-width: 100px;
      background: $border-color;
      color: $hol-blue;

      &.mat-button-toggle-checked {
        background: $hol-blue;
        color: white;
      }
    }
  }

  &.roles-buttons {
    box-shadow: none !important;
    font-weight: bold;
    flex-wrap: wrap;

    .mat-button-toggle {
      border-width: 2px;
      border-style: solid;
      height: 28px;
      border-radius: 14px;
      margin: 2px 10px 2px 0;
      /*&:not(:last-child) {
        margin: 2px 10px 2px 0;
      }*/
      .mat-button-toggle-label-content {
        line-height: 24px;
        padding: 0 10px;
      }

      &.mat-button-toggle-disabled {
        opacity: 0.3;
      }
    }
  }
}

.upload-button {
  color: $hol-blue;
  border: solid 1px $hol-blue;
  margin: 0 5px;

  span {
    margin-left: 5px;
    text-transform: uppercase;
  }
}

// .displayAllText {
//   margin-left: 5px;
//   display: flex;
//   align-items: center;
//   height: 100%;
//   width: 150px;
//   align-content: center;

//   label {
//     display: flex;
//     align-items: center;
//     height: 10px;

//     strong {
//       margin-right: 5px;
//     }

//     md-checkbox.md-checked .md-icon {
//       background-color: rgb(63, 97, 133) !important;
//     }

//     md-checkbox.md-checked .md-ink-ripple {
//       color: rgb(63, 97, 133) !important;
//     }

//     .md-ripple-container {
//       display: none !important;
//     }

//     .md-container:before {
//       display: none !important;
//     }
//   }
// }
