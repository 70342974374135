$body-bg: #000;
$enable-print-styles: false;
@media only print {
  .cdk-global-scrollblock.cdk-global-scrollblock {
    position: initial;
  }

  .left-menu,
  header-component,
  .form-group,
  button,
  nav,
  .hide-for-print {
    display: none !important;
  }

  #main {
    left: 0;
  }

  .iscroll-wrapper,
  .hol-box {
    overflow: visible !important;
    position: relative !important;
  }
  .hol-row {
    padding: 0 !important;
  }
  .time-slot {
    page-break-after: always;
  }
  a[href]:after {
    content: none !important;
  }
  html {
    height: auto !important;
  }
  body {
    height: auto !important;
    overflow: scroll !important;

    #main {
      position: relative !important;
      left: 0;

      [ui-view] {
        height: auto !important;

        .page {
          height: auto !important;
        }
      }
    }
  }
}
