@import 'src/styles/ui-kit/variables';

$indicator-colors: (
  $no-info-criticity-color,
  $lowest-criticity-color,
  $medium-criticity-color,
  $high-criticity-color,
  $highest-criticity-color
);

mat-select {
  @for $i from 1 through length($indicator-colors) {
    &.indicator-color-#{$i - 1} {
      .mat-select-value {
        color: nth($indicator-colors, $i);
      }
    }
  }
}

.mat-select-panel .mat-option {
  &.custom-option-departure.custom-option-departure {
    margin: 0;
    padding: 0 0 0 20px;
    height: 2.5rem;
    font-size: 14px;
  }

  &.custom-option-indicators.custom-option-indicators {
    margin: 0;
    padding: 0 0 0 20px;
    height: 2.5rem;
    font-size: 14px;

    @for $i from 1 through length($indicator-colors) {
      &.indicator-color-#{$i - 1} {
        color: nth($indicator-colors, $i);
      }
    }
  }
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 10001;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  z-index: 1000000;
}
