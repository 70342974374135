@import '../ui-kit/variables';
@import '../mixins';

.chat-button {
  height: 100%;

  .icon-container {
    position: relative;
    .hol-error {
      position: absolute;
      top: 20px;
      right: 5px;
      font-size: 1em;
    }
  }
  &.unread {
    animation: blink-background 1s ease-in-out infinite alternate;
  }
}

@keyframes blink-background {
  to {
    background: $error-color;
  }
  to {
    color: white;
  }
}

.chat-component {
  background: lightgoldenrodyellow;
  position: absolute;
  right: 0;
  top: $header_height;
  width: 300px;
  bottom: 0;
  z-index: 75;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.36);

  .hol-box {
    border: none;
  }
  .hol-box__header {
    background: $background-secondary;
    &.subheader {
      padding: 10px 0 0 0;
      min-height: 0;
      .dropdown-container {
        width: 100%;
      }
      .btn-group {
        border-top: 1px solid $border-color;
        width: 100%;
      }
      .crew-event-item,
      .occ-event-item {
        background: none;
        width: calc(100% - 30px);
        display: inline-block;
        font-weight: initial;
        color: initial;
        font-size: 14px;
      }
      .dropdown-toggle {
        cursor: pointer;
        &:hover {
          background: $border-color;
        }
      }
      .channel-dropdown {
        right: 0;
        padding: 0;
        border-radius: 0;
        margin-top: 0;
        max-height: 400px;
        overflow: auto;

        li {
          &:not(:first-child) {
            border-top: 1px solid $border-color;
          }
          &:hover,
          &:focus {
            background: $border-color;
          }
          .unread-indicator {
            background: red;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            color: white;
            font-size: 12px;
            display: inline-block;
            text-align: center;
            line-height: 20px;
          }
          &.active {
            background: #337ab7;
            .crew-event-item {
              color: white;
              .flight-number {
                color: white;
              }
            }
            .occ-event-item {
              color: white;
              .event-code,
              .event-title {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

.chat-channel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  padding: 0;
  background: $color-chat-background;
  .chat-error {
    background: $color-chat-background;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .chat-messages {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 114px;
    right: 0;
    left: 0;
    &.no-messages {
      background: $color-chat-background;
    }
    ul.hol-list {
      display: flex;
      flex-direction: column;
    }
    li {
      padding: $content-padding;
      transition: border-color 0.3s ease;
      &:before {
        color: $error-color;
        transition: opacity 0.3s ease;
        content: 'new message';
        margin: -10px 0;
        font-size: 0.8em;
        position: absolute;
        padding: 0 4px;
        right: 0;
        opacity: 0;
      }
      &.new {
        border-top-color: $error-color;
        &:before {
          opacity: 1;
        }
      }
      .message-header {
        text-align: left;
        .username {
          color: $text-color;
          font-style: italic;
          font-weight: bold;
          font-family: $condensed-font-family;
        }
        .time {
          color: $text-color--light;
          margin-left: $content-padding;
        }
      }
      .message {
        font-family: $condensed-font-family;
      }
    }
    .chat-no-messages {
      text-align: center;
      opacity: 0.5;
      display: flex;
      margin: auto;
      flex-direction: column;
      .icon {
        height: 80px;
        width: 74px;
        color: #babdbf;
        margin: auto;
      }
      .text {
        color: #2d2d2d;
        font-weight: bold;
        padding: $content-padding;
        font-family: $condensed-font-family;
      }
    }
  }
  .chat-input {
    background: $color-chat-background;
    padding: $content-padding;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    .form-group {
      margin-bottom: 0;
      .form-control {
        width: 100%;
      }
    }
  }
}
