.iscroll-shadow-container {
  position: absolute;
  bottom: -5px;
  top: 0;
  left: -5px;
  right: -5px;
  overflow: hidden;
  pointer-events: none;

  &.no-scroll {
    display: none;
  }

  .iscroll-shadow {
    height: 40px;
    width: 100%;
    position: absolute;
    transition: opacity, background 500ms ease-in-out;

    &.bottom {
      bottom: -20px;
    }
    &.top {
      top: -20px;
    }

    &.hidden {
      opacity: 0;
    }

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b7b7b7+0,2a4057+99&0.4+0,0+73 */
    background: -moz-radial-gradient(
      ellipse at center,
      rgba(183, 183, 183, 0.4) 0%,
      rgba(79, 95, 112, 0) 73%,
      rgba(42, 64, 87, 0) 99%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      ellipse at center,
      rgba(183, 183, 183, 0.4) 0%,
      rgba(79, 95, 112, 0) 73%,
      rgba(42, 64, 87, 0) 99%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      rgba(183, 183, 183, 0.4) 0%,
      rgba(79, 95, 112, 0) 73%,
      rgba(42, 64, 87, 0) 99%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66b7b7b7', endColorstr='#002a4057',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    &.full {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3f6185+0,2a4057+100&0.55+0,0+68 */
      background: -moz-radial-gradient(
        ellipse at center,
        rgba(63, 97, 133, 0.55) 0%,
        rgba(49, 75, 102, 0) 68%,
        rgba(42, 64, 87, 0) 100%
      ); /* FF3.6-15 */
      background: -webkit-radial-gradient(
        ellipse at center,
        rgba(63, 97, 133, 0.55) 0%,
        rgba(49, 75, 102, 0) 68%,
        rgba(42, 64, 87, 0) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(
        ellipse at center,
        rgba(63, 97, 133, 0.55) 0%,
        rgba(49, 75, 102, 0) 68%,
        rgba(42, 64, 87, 0) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8c3f6185', endColorstr='#002a4057',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
  }
}
