#page-tasks {
  .event-filter,
  .function-filter {
    display: inline-block;
  }

  .task-content {
    height: 100%;

    .hol-box__content {
      height: 100%;
    }
  }
}
