@import './ui-kit/variables';
// TODO - A modifier qd les breakpoints seront utilisé partout
body.hol-mobile {
  .clickable.ng-click-active {
    opacity: 0.7;
  }
  #main {
    position: absolute;
    left: 0;
    margin-bottom: 0;
    padding: 0;
    .hol-row {
      overflow: auto;
      margin-left: 0;
      margin-right: 0;
      padding: 5px 0;
      > .col-xs-12 {
        padding: 0;
        margin-bottom: $content-padding;
        height: auto;
      }
    }

    .mcc-row {
      background: white;
      padding: 0;

      .last-importdate-container {
        width: 100%;
        max-height: 50px;
        min-height: 50px;
        font-size: 13px;
        display: flex;
        align-items: center;
        padding-left: 40px;
        .importDate-responsive {
          margin-left: $content-padding;
        }
      }
    }

    .mcc-li {
      padding: 5px 0;
      &:first-child {
        padding-top: 0;
      }
    }

    .hol-box .hol-box__content .iscroll-wrapper {
      position: relative;
      top: initial !important;
      left: initial;
      right: initial;
      bottom: initial;
    }

    .status-decision-container {
      .history-title {
        font-weight: bold;
        color: $primary-color;
        margin-bottom: $content-padding;
      }
    }
  }

  .md-dialog-container md-dialog {
    margin-left: 0;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    form {
      height: 100%;
    }
    .occ-table {
      .iscroll-wrapper {
        position: relative;
        top: initial !important;
        left: initial;
        right: initial;
        bottom: initial;
      }
    }
  }
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      max-width: 100% !important;
      height: 100%;
      width: 100%;
      .mat-dialog-container {
        width: 100%;
        height: 100%;
        .hol-dialog-wrapper {
          min-width: 100%;
          max-width: 100%;
          .mat-dialog-content {
            flex: 1;
            max-height: 100%;
          }
        }
      }
    }
  }
  .cdk-overlay-container {
    .hol-full-modal {
      margin-left: initial;
      min-width: initial;
    }
  }

  .header-component .buttons-group button.header-button:hover {
    background: transparent !important;
  }
}
