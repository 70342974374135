@import 'variables';
@import '../mixins';

.hol-box {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border: 1px solid #eaeaea;
  overflow: hidden;
  height: 100%;
  position: relative;

  .hol-box__header {
    color: $primary-color;
    min-height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    padding: $content-padding $content-padding 0 $content-padding;

    &--col {
      flex-flow: column nowrap;
    }

    .hol-box__subtitle {
      font-weight: normal;
      font-size: 14px;
    }

    button {
      margin-top: 0;
      margin-bottom: 0;
    }

    .md-icon-button {
      font-family: $default-font-family;
      background-color: $primary-color;
      color: white;
      margin-left: 20px;
      margin-right: 0;

      &:hover,
      &:active,
      &:focus {
        background-color: $primary-color;
        color: white;
      }

      .open-modal {
        color: $crew-color;
        background-color: white;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: $crew-color;
        color: white;
      }
    }

    .md-icon-button-inverse {
      font-family: $default-font-family;
      border: 1px solid $primary-color;
      border-radius: 10px;
      background-color: white;
      color: white;
      margin-left: 20px;
      margin-right: 0;

      &:hover,
      &:active,
      &:focus {
        background-color: white;
        color: $primary-color;
      }

      .open-modal {
        color: $crew-color;
        background-color: white;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: $crew-color;
        color: white;
      }
    }

    .open-modal-text {
      color: white;
    }
  }

  .hol-box__content {
    padding: $content-padding;
    flex: 1;
    position: relative;

    .iscroll-wrapper {
      position: absolute;
      top: $content-padding;
      left: $content-padding;
      right: $content-padding;
      bottom: $content-padding;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    &.hol-no-padding {
      .iscroll-wrapper {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .hol-box__footer {
    padding: 0 $content-padding $content-padding $content-padding;
    min-height: 40px;
    position: relative;
  }
}
