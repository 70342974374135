@use 'sass:math';
@import '../ui-kit/variables';
@import 'dashboard';
@import 'team';
@import 'checklist';
@import 'history';
@import 'modals/user.modal';

.user-autocomplete-item {
  .fonctions {
    margin-left: 2 * $content-padding;
  }

  .tiredness-indicator {
    background: transparent;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    margin: 0;
  }

  .user-text {
    display: inline-block;
    padding: 0 10px;
    margin: 0;
  }
}

.crew-table {
  .iscroll-wrapper > * > .hol-list > li:first-child {
    font-weight: normal;
  }
  li.ng-enter-stagger,
  li.ng-leave-stagger {
    transition-delay: 0.02s;
  }
  .created-by {
    margin-left: math.div($content-padding, 2);
  }
  .col-user {
    padding: 5px $content-padding 3px $content-padding;
    margin: -2px $content-padding 0 math.div($content-padding, 2);
    line-height: 1em;
    &:not([class*='filter-color']) {
      background: $background-secondary;
    }
  }
  .situation-event {
    line-height: 1em;
    text-align: center;
    margin-left: math.div($content-padding, 2);
  }
  .date {
    background: none;
  }
}

.crew-event-counter {
  padding: 8px 7px 3px 7px;
  font-weight: bold;
  line-height: 2em;
  white-space: nowrap;
  font-family: $condensed-font-family;
  .icon {
    margin-right: 5px;
  }
}
