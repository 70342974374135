.connected-users-box {
  li {
    font-weight: bold;
    .hol-duty-indicator {
      width: 6px;
      height: 6px;
      margin: 0 5px 10px -5px;
    }
  }
}
