$hol-red: #ff0036;
$hol-orange: #f4902f;
$hol-green: #00c179;

$primary-color: #3f6185;
$primary-color--dark: #2a4057;

$text-color: #2d2d2d;
$text-color--light: #787878;
$border-color: #e7e7e7;
$background-secondary: #f1f5f5;

$separator-color: #979797;
$separator-color-light: #f3f6f4;
$separator-color--dark: #4a4a4a;

$border-color: #c9cbcb;

$crisis-color: #ff0825;
$training-color: $crisis-color;
//$occ-color: #0030bf;
$occ-color: #264dbf;
//$ecl-color: #e35700;
$ecl-color: #e36517;
$ops-color: #0b84b0;
$crew-color: #008568;
//$mcc-color: #bb00ff;
$mcc-color: #c933ff;
//$goc-color: #8a4801;
$goc-color: #8a4f0f;

$online-color: $hol-green;
$offline-color: $hol-red;
$available-offline-color: $hol-orange;

$validate-color: $hol-green;
$cancel-color: $hol-red;

$todo-color: #707070;
$done-color: $hol-green;
$frozen-color: $hol-red;
$backtodo-color: $hol-orange;
$not-applicable-color: #7f7f7f;

$error-color: $hol-red;
$warning-color: $hol-orange;
$delete-color: #d70303;
$deactivate-color: #7f7f7f;

$event-color: #007aff;

$content-padding: 8px;
$border-radius: 4px;

$lowest-criticity-color: #ffd479;
$low-criticity-color: #ffa03a;
$medium-criticity-color: #fb9190;
$high-criticity-color: #ff2600;
$highest-criticity-color: #cd2072;

$no-info-criticity-color: #7f7f7f;

$transition_time: 0.2s;
