@import './mixins.scss';

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-Regular.woff2') format('woff2'), url('/assets/fonts/Nunito-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-Cn';
  src: url('/assets/fonts/AvenirNextLTPro-Cn.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Condensed Demi';
  src: url('/assets/fonts/AvenirNextCondensed-DemiBold.woff2') format('woff2'),
    url('/assets/fonts/AvenirNextCondensed-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Condensed Medium';
  src: url('/assets/fonts/AvenirNextCondensed-Medium.woff2') format('woff2'),
    url('/assets/fonts/AvenirNextCondensed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Condensed Bold';
  src: url('/assets/fonts/AvenirNextCondensed-Bold.woff2') format('woff2'),
    url('/assets/fonts/AvenirNextCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

html {
  background-color: $color_background_main;

  body {
    background-color: $color_background_main;
    padding: 0;
    font-family: $default-font-family;
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  margin: 0;
}

h2,
h3 {
  font-weight: 200;
  letter-spacing: -0.2px;
}

#main {
  position: absolute;
  top: 0;
  left: $nav_width;
  bottom: 0;
  right: 0;
  background-size: cover;
  color: $color_check_list_background;
  -webkit-overflow-scrolling: touch;

  [ui-view] {
    height: 100%;

    .page {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
    }
  }
}

.col-attachements img[src*='assets/images/note.svg'] {
  width: 25px;
  margin-bottom: 3px;
}

.loadingOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: $color_loading_overlay;
}

.text-error {
  color: #d9534f;
}

.notClickable {
  pointer-events: none !important;
}

body.hol-desktop .clickable:hover {
  opacity: 0.7;
  cursor: pointer;
}

.contentCentered {
  @include center-vertically();
  text-align: center;
}

.rightContentCentered {
  @include center-right-vertically();
}

.leftContentCentered {
  @include center-left-vertically();
}

.modal {
  button.close {
    font-size: 36px;
  }

  .modal-header:not(.task) {
    color: #fff;
    background-color: $modal-headers-color;
  }
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.unfocusable {
  @include unfocus();
}

/* Remove text selection */
* {
  /* Remove blue border for focus */
  &:focus {
    @include unfocus();
  }
}

/* Reset text selection for input and textarea */
input,
textarea {
  -webkit-touch-callout: default;
  font-family: Arial, sans-serif;

  &:focus:not(.mat-input-element) {
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}

.unseen-pastille {
  width: 8px;
  height: 8px;
  background-color: $color_frozen;
  border-radius: 4px;
  position: absolute;
  top: 5px;
  left: 8px;

  @keyframes pastille_leave_animation {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(90deg);
      opacity: 0;
    }
  }
  @keyframes pastille_enter_animation {
    from {
      transform: rotateY(90deg);
    }
    to {
      transform: rotateY(0deg);
    }
  }

  &.ng-leave {
    animation: 0.5s pastille_leave_animation;
  }

  &.ng-enter {
    animation: 0.5s pastille_enter_animation;
  }
}

nav .blink-item {
  @keyframes blink {
    50% {
      background-color: #eeeeee;
    }
  }
  @keyframes blinkText {
    50% {
      color: #000000;
    }
  }
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  a {
    animation-name: blinkText;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}

.responsiveIcon {
  .icon {
    display: none;
  }

  .text {
    display: initial;
  }
}

@media (max-width: 1024px) {
  .responsiveIcon {
    .icon {
      display: initial;
    }

    .text {
      display: none;
    }
  }
}

.title-box-occ,
.title-box-crew,
.title-box-goc,
.title-box-erp,
.title-box-mcc {
  @include title-font-style(1.4em);
}

.title-card-vacation {
  @include title-font-style(18px);
}

.title-scenario-checklist-event,
.title-checklist-vacation {
  @include title-font-style(16px);
}

.crisis-dashboard-title {
  @include title-font-style(20px);
  font-weight: bold;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-align-items-start {
  align-items: flex-start;
}

.ppv-no-warning {
  background: $color_notApplicable;
}

.ppv-affected {
  background: $color_done;
}

.ppv-in-progress {
  background: $color_orange;
}

.ppv-no-affected {
  background: $color_frozen;
}

.ppv-no-5o {
  background: $color_notApplicable;
  font-style: italic;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.align-items-center {
  align-items: center;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.justify-content-between {
  justify-content: space-between;
}
