@import './variables';
@import '../mixins';

.mat-button.back-link {
  .mat-button-wrapper {
    font-size: 16px;
    font-weight: 400;
    font-family: $default-font-family !important;
  }
}

button:not(.mat-mini-fab):not(.header-button):not([mat-dialog-close]) {
  letter-spacing: 0.09em;

  [class^='hol-icon-'],
  [class*=' hol-icon-'] {
    line-height: 1.25em;
    font-size: 1.25em;
    margin-right: 0.25em;
  }

  font-size: 14px !important;

  &.hol-attachment-btn {
    text-transform: uppercase;

    .mat-button-wrapper {
      letter-spacing: 0.09em !important;
    }
  }

  &.btn-link:not(disabled) {
    text-decoration: underline;
  }

  // mat-button + icon
  .mat-button-wrapper {
    letter-spacing: 0;

    .tag-name {
      letter-spacing: 0.09em;
    }

    .mat-icon {
      /*
      margin-left: -8px;
      */
    }

    span .mat-icon {
      margin-left: auto;
      /*margin-right: -8px;*/
    }
  }

  &.more-small {
    margin-top: 5px;
    font-size: 12px !important;
    padding: 5px;
    height: 20px;
    line-height: 0 !important;
    letter-spacing: 0;
  }

  &.add-button:not(.md-button) {
    background-color: #3f6185;
    color: #fff;
    width: 35px;
    height: 35px;
    margin-top: -8px;
    margin-right: 20px;
  }
}

// Material
mat-button-toggle-group {
  &.roles-buttons {
    box-shadow: none;
  }
}

/* Secondary button styling */

.mat-raised-button.mat-secondary,
.mat-flat-button.mat-secondary,
.mat-fab.mat-secondary,
.mat-mini-fab.mat-secondary {
  color: $hol-blue;
  border: 1px solid $hol-grey;
  box-shadow: none;
  background-color: transparent;
}

/* Success styling */
.mat-button.mat-success,
.mat-stroked-button.mat-success {
  color: $hol-success !important;
}

.mat-button:not(:disabled).mat-success:hover,
.mat-stroked-button:not(:disabled).mat-success:hover {
  background-color: rgba(88, 150, 14, 0.12);
}

.mat-raised-button.mat-success,
.mat-flat-button.mat-success,
.mat-fab.mat-success,
.mat-mini-fab.mat-success {
  color: #fff;
  background-color: $hol-success;
}

.mat-icon-button.mat-success {
  color: $hol-success;
}

// Disabled button styling
.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled],
.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled] {
  background-color: #ffffff;
  border: 1px solid $hol-grey;
}

/* Light button styling */

.mat-raised-button.mat-light,
.mat-flat-button.mat-light,
.mat-fab.mat-light,
.mat-mini-fab.mat-light {
  color: $hol-black;
  background-color: fff;
}

/* Dark button styling */

.mat-raised-button.mat-dark,
.mat-flat-button.mat-dark,
.mat-fab.mat-dark,
.mat-mini-fab.mat-dark {
  color: #fff;
  background-color: $hol-black;
}

/* Mat-button-toggle styling */
.mat-button-toggle {
  letter-spacing: 0.09em;

  [class^='hol-icon-'],
  [class*=' hol-icon-'] {
    line-height: 1.25em;
    font-size: 1.25em;
    margin-right: 0.25em;
  }

  &.mat-success {
    color: $hol-success !important;

    &:not(.mat-button-toggle-disabled):hover,
    &.mat-button-toggle-checked {
      background-color: $hol-success !important;
      color: #fff !important;
    }
  }

  &.mat-warn {
    color: $hol-warn !important;

    &:not(.mat-button-toggle-disabled):hover,
    &.mat-button-toggle-checked {
      background-color: $hol-warn !important;
      color: #fff !important;
    }
  }

  &.mat-basic {
    color: $hol-dark_grey !important;
    border-color: $hol-dark_grey !important;
  }
}

button {
  &.header-button {
    position: relative;

    &__deployed {
      &::after {
        content: '';
        display: flex;
        height: 2px;
        background: white;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
      }
    }
  }

  &.mat-mini-fab:not(.add-button) {
    width: 24px;
    height: 24px;
    box-shadow: none !important;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    [class^='hol-icon-'],
    [class*=' hol-icon-'] {
      font-size: 20px;

      &.occ-linked-icon {
        font-size: 1em;
      }
    }

    // font-size: 14px !important;
    .mat-button-wrapper {
      padding: 0 !important;
      line-height: 1;
    }

    &.hol-invert {
      border: 1px solid $hol-dark_grey;
      @include colorButton(#fff, $hol-primary);
    }

    &.hol-invert-light {
      border: 1px solid $hol-light-grey;
      @include colorButton(#fff, $hol-black);

      &.selected {
        border: solid 1px $hol-blue;
        @include colorButton($hol-light-blue, $hol-blue);
      }

      &:disabled {
        border: solid 1px $hol-light-grey;
        @include colorButton($hol-light-grey, $hol-black);
      }
    }
  }
}

.md-icon-button-inverse {
  font-family: $default-font-family;
  border-radius: 50%;
  background-color: white;
  color: $primary-color;
  margin-left: 20px;
  margin-right: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: white;
    color: $primary-color;
  }

  .open-modal {
    color: $primary-color;
    background-color: white;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $primary-color;
    color: white;
  }
}
