@import 'variables';
@import '../mixins';

.md-dialog-container {
  margin-top: 0;
  @include center-vertically();

  md-dialog {
    &.long-title md-toolbar .md-toolbar-tools {
      max-height: none;
      height: auto;
      h2 {
        text-transform: none;
      }
    }
    min-width: 70%;
    max-width: 70%;
    width: 70%;
    margin-left: $nav_width;
    max-height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    &.hol-modal-alert,
    &.hol-modal-confirm {
      max-width: 50%;
      min-width: 25%;
      width: auto;
    }

    md-toolbar {
      min-height: 64px;
      .md-toolbar-tools {
        color: $primary-color;
        min-height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        padding: $content-padding $content-padding * 2 0 $content-padding * 2;
        background: white;
        h2 small {
          font-size: 85%;
          font-weight: normal;
          color: inherit;
        }

        button.md-button {
          font-size: 22px;
          margin: 0 0 0 $content-padding;
          padding: 0;
          color: inherit;
          min-width: 40px;
        }
      }
    }

    .header-text-no-overflow {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    md-dialog-content {
      padding: $content-padding $content-padding * 2;
      flex: 1;
      position: relative;

      md-list,
      md-list-item {
        padding: 0;
      }

      input,
      textarea,
      select {
        width: 100%;
        border: 1px solid #ccc;
        font-weight: 300;
        border-radius: 0;
        padding: 6px;
      }
      .input-group-addon {
        border-radius: 0;
      }

      .dialog-title {
        text-align: left;
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .popup-content {
        text-align: left;
        font-size: 1em;
        margin-bottom: 5px;
      }

      .checkbox-group {
        .checkbox {
          margin: 0;
          display: block;
        }
        md-checkbox {
          margin-bottom: 5px;
        }
      }

      .next-info {
        float: right;
        font-weight: bold;
      }

      .next-info-container {
        margin-bottom: $content-padding;
        .time {
          color: $text-color--light;
        }
        .message {
          width: 100%;
          word-break: break-all;
        }
      }
    }

    md-dialog-actions {
      padding: 0 $content-padding * 2 $content-padding $content-padding * 2;
      position: relative;
      background: white;
      flex-shrink: 0;
      .dialog-actions {
        width: 100%;
        @include center-vertically();
      }
    }

    &.modal-secondary {
      md-toolbar {
        .md-toolbar-tools {
          background: #eeeeee;
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
          color: $text-color;
          padding-top: 0;
          small {
            text-transform: none;
          }
          button {
            color: $primary-color;
          }
        }
      }
      md-dialog-actions {
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
        padding-bottom: 0;
        .dialog-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          md-checkbox {
            margin-bottom: 0;
          }
          button {
            margin-top: 15px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.popupbutton {
  text-align: center;

  button {
    margin: 0 auto !important;
    height: 80px;
    width: 80px;
    min-width: 80px;
    border-radius: 50px;
    padding: 0;
    display: block;
    background-color: $color_done !important;
    font-size: 19px;
    text-align: center;

    i {
      color: white;
      display: inline-block;
      font-size: 35px;
      line-height: 80px;
      font-weight: 100;
      margin: 0;
    }

    &[disabled] {
      opacity: 0.3;
    }
  }

  .button-label {
    color: #6f6f6f;
    font-weight: 200;
    margin-top: 9px;
    display: inline-block;
  }
}

.cdk-overlay-container {
  .hol-full-modal {
    margin-left: 78px;
    max-width: 946px !important;
    width: 946px;
  }
}

.md-dialog-container md-dialog md-dialog-content.custom-content-styles {
  padding: 0px 20px;
  flex: 1;
  position: relative;
}
