@use 'sass:math';
@import '../../ui-kit/variables';
@import '../../mixins';

.modal-crew-user {
  $anim-duration: 0.3s;
  $easing-function: ease;

  min-height: 80%;
  max-height: 80% !important;
  .user-modal-search {
    display: flex;
    flex-direction: column;
    transition: transform $anim-duration $easing-function;
    position: absolute;
    width: 100%;

    &.ng-enter {
      transform: translateY(-100%);
      &.ng-enter-active {
        transform: translateY(0);
      }
    }
    &.ng-leave {
      transform: translateY(0);
      &.ng-leave-active {
        transform: translateY(-100%);
      }
    }

    .search-user {
      position: relative;
      .auto-complete-loader {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .user-modal-details {
    display: flex;
    flex-direction: row;
    flex: 1;

    &.ng-enter,
    &.ng-enter-prepare {
      transition-duration: $anim-duration;
      //transition-delay: $anim-duration;
      .user-panel {
        transition: transform $anim-duration $easing-function;
        transform: translateX(-100%);
      }
      .situation-panel {
        transition: transform $anim-duration $easing-function;
        transform: translateX(100%);
      }
      &.ng-enter-active {
        .user-panel,
        .situation-panel {
          transform: translateX(0);
        }
      }
    }
    &.ng-leave {
      transition-duration: $anim-duration;
      .user-panel,
      .situation-panel {
        transition: transform $anim-duration $easing-function;
        transform: translateX(0);
        position: absolute;
      }
      &.ng-leave-active {
        .user-panel {
          left: 0;
          transform: translateX(-100%);
        }
        .situation-panel {
          right: 0;
          transform: translateX(100%);
        }
      }
    }

    .hol-box {
      height: auto;
      .hol-box__header {
        padding: 0;
        .md-icon-button {
          background: none;
          &:hover {
            color: $primary-color;
          }
        }
      }
      .hol-box__content {
        padding: $content-padding 0;
        .iscroll-wrapper {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
    }

    .item-selected {
      background: #d6d6d6;
    }

    .item-unselected {
      background: #eeeeee;
    }

    .user-panel {
      flex: 4;
      flex-shrink: 1;
      background: #eeeeee;
      display: flex;
      flex-direction: column;
      min-width: 40%;
      max-width: 40%;

      .user-container {
        display: flex;
        align-items: center;
        margin-top: 10px;

        app-tiredness-indicator {
          vertical-align: middle;
        }
        .icon {
          margin-right: $content-padding;
        }
      }

      .md-toolbar-tools {
        font-size: 14px;
        display: block;
        font-weight: normal;
        background: #eeeeee;
        color: $text-color;
        height: auto;
        max-height: none;
        min-height: 120px;

        .btn-change-user {
          margin-right: -10px;
          font-size: 12px;
          .icon {
            padding: 5px;
            background: $text-color--light;
            border-radius: 50%;
            color: white;
            margin-left: 1px;
          }
        }
        .user-name {
          font-weight: bold;
          font-size: 1.4em;
          span {
            vertical-align: middle;
          }

          .tiredness-indicator {
            width: 20px;
            height: 20px;
            background: transparent;
            display: inline-block;
            border-radius: 50%;
          }
        }
      }

      li {
        margin: 2px 10px;
      }

      li.disabled {
        opacity: 0.3;
        &.hol-clickable:hover {
          opacity: 0.55;
        }
      }
      li.highlight {
        @keyframes highlight {
          0% {
            box-shadow: 0 1px 2px 0 rgba(63, 97, 133, 0.5);
          }
          50% {
            box-shadow: 0 1px 2px 3px rgba(63, 97, 133, 0.5);
          }
          0% {
            box-shadow: 0 1px 2px 0 rgba(63, 97, 133, 0.5);
          }
        }
        animation-duration: 1s;
        animation-name: highlight;
        animation-iteration-count: infinite;
      }
      li.separator {
        border-bottom: 1px solid $text-color--light;
        margin: 15px 2px;
      }
      li.selected.hol-clickable {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
        position: relative;
        .situation-item {
          .item-selected {
            color: blue;
          }
          input {
            max-width: 120px;
          }

          .edit-status-text {
            margin: 5px 0px;
          }

          .action-holder {
            height: 50px;
            .btn-square {
              min-width: 0px;
              width: 40px;
            }
          }

          .situation-textarea {
            margin: 5px 0px;
          }
        }
      }

      .collapse-container {
        text-align: right;
        height: 2em;
        .btn-collapse {
          background: transparent;
          i {
            transform: rotateZ(180deg);
          }

          &.collapsed {
            i {
              transform: rotateZ(0deg);
            }
          }
        }
      }
    }
    .form-group {
      flex: 6;
      display: flex;
      flex-direction: column;
      min-width: 60%;
      max-width: 100%;
      margin: 0;

      .situation-panel {
        flex: 6;
        display: flex;
        flex-direction: column;
        min-width: 60%;
        max-width: 100%;
        padding: 0;
        margin: 0;
        .form-group:not(:last-of-type) {
          border-bottom: 1px solid $border-color;
          padding-bottom: $content-padding;
        }

        .direction {
          .hol-radio-btn-group {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            .md-button {
              flex-grow: 1;
            }
            .consultation {
              flex: auto;
            }
          }
          .text-container {
            font-weight: normal;
            color: $text-color;
            line-height: 1.2em;
            small {
              font-size: 11px;
            }
            strong {
              color: $primary-color;
              font-family: $condensed-font-family;
            }
          }
        }

        .consultation {
          background-color: $color-user-modal-background;
        }

        .consultation-footer {
          font-size: 1em;
          padding-right: 20px;
          padding-bottom: 20px;
          margin: 0;
          text-align: right;
          background-color: $color-user-modal-background;
        }

        .consultation-status-text {
          color: $color_notApplicable;
          text-align: center;
          font-size: 12px;
        }

        .consultation-text-area {
          border: 0px;
          min-height: 1em;
          align-content: center;
        }

        .consultation-question-container {
          margin: $content-padding 0;
          display: flex;
          .question {
            width: 80px;
            padding-top: 6px;
            margin-right: 10px;
            color: $color-notApplicable;
            text-transform: uppercase;
            font-size: 12px;
            text-align: right;
            font-weight: bold;
          }
          .response {
            flex: 1;
            background: white;
            padding: 6px;
            &.date {
              text-align: center;
            }
            &.big {
              min-height: 72px;
            }
          }

          .readonly-status-indicator {
            align-self: stretch;
            display: flex;
            align-items: center;
          }
        }

        .info {
          textarea:not(:disabled) {
            min-height: 150px;
            padding-bottom: 51px;
          }
          .help-btn-container {
            margin-top: -51px;
            border-top: 1px solid $border-color;
            display: flex;
            overflow: hidden;
            .help-btn {
              text-overflow: ellipsis;
              text-transform: none;
              font-size: 12px;
              font-weight: normal;
              background: white;
              color: $text-color;
              border: 1px solid $border-color;
              min-width: 0;
              &:hover {
                background: $background-secondary;
              }
              &:not(:last-child) {
                margin-right: 0;
              }
            }
          }
          &.waiting {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .status-text {
              max-width: calc(100% - 140px);
              display: block;
              flex: 1;
              input:not(:disabled) {
                height: 34px + 51px;
                padding-bottom: 51px;
              }
            }
          }
        }

        .linked-to-event-container {
          margin-top: $content-padding;
          md-radio-group {
            display: inline-block;
            margin-left: $content-padding;
            md-radio-button {
              display: inline-block;
              margin-top: 0;
              margin-bottom: $content-padding;
            }
          }
        }
        .hol-spaced-line .input-group {
          flex: 1;
        }
        .event-details {
          .event-container {
            margin-bottom: $content-padding;
            .select-line {
              align-items: baseline;
              select {
                margin-bottom: math.div($content-padding, 2);
              }
            }
          }
          .input-group {
            &:first-child {
              margin-right: math.div($content-padding, 2);
            }
            &:last-child {
              margin-left: math.div($content-padding, 2);
            }
          }
        }

        .status {
          .status-info {
            > *:not(:first-child) {
              margin-left: $content-padding;
            }
          }
          .input-group.date {
            min-width: 130px;
            max-width: 130px;
          }
          .time {
            min-width: 60px;
            max-width: 60px;
            text-align: center;
          }
          .hol-radio-btn-group {
            flex-wrap: nowrap;
            .md-button {
              flex-grow: 1;
            }
          }
        }

        .actions {
          button {
            margin-right: 0;
          }
        }

        .not-authorized-msg {
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          color: $error-color;
          background: $background-secondary;
          padding: $content-padding 20%;
          font-weight: bold;
        }

        .readonly-status-indicator {
          padding: math.div($content-padding, 2) $content-padding;
          font-weight: bold;
          &.accepted {
            background: $validate-color;
            color: white;
          }
          &.refused {
            background: $error-color;
            color: white;
          }
          &.neutral {
            background: $border-color;
            color: $primary-color;
          }
          &.waiting {
            background: $warning-color;
            color: white;
          }
        }

        .notifications {
          min-width: 200px;
          .notifications-label {
            padding-top: $content-padding;
          }
        }
      }
    }
  }
}
