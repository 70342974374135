@import './variables';
@import 'src/styles/mixins';

.new-holis-theme {
  height: 100%;
  a,
  a:visited {
    color: $hol-primary;
  }
  small,
  strong {
    font-family: $default-font-family;
  }
  .hol-content-box {
    height: 100%;
    width: 100%;
    font-size: 16px;
    color: $hol-black;
    .disabled {
      opacity: 0.6;
    }
    label {
      font-family: $condensed-semibold-font-family;
      font-size: 14px;
      opacity: 0.8;
      &:not(.mat-checkbox-layout) {
        margin-bottom: 16px;
      }
    }
    .info-label {
      font-family: $default-font-family;
      margin-bottom: 16px;
    }
    &__header {
      max-height: 2.5em;
      min-height: 2.5em;
      margin-bottom: 5px;
    }
    &__content {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;
      background-color: #ffffff;
      &__col {
        display: flex;
        flex-direction: column;
        padding: 1em;
        padding-right: 24px;
        &__header {
          max-height: 3em;
          min-height: 3em;
        }
        &__content {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          &.tabs {
            height: 100%;
          }
          &--background-title {
            background-color: rgba(201, 201, 201, 0.2);
            padding: 0.5em;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;
            font-family: $condensed-semibold-font-family;
          }
          &--container {
            flex: 1;
            overflow: hidden;
          }
          .decimal-rounded-list {
            counter-reset: count;
            .count {
              margin-left: 3em;
              margin-bottom: 4em;
              &:before {
                content: counter(count);
                counter-increment: count;
                position: relative;
                left: -3em;
                background: #e8eaf6;
                height: 2em;
                width: 2em;
                line-height: 2em;
                text-align: center;
                border-radius: 2em;
                display: inline-block;
                font-family: $condensed-semibold-font-family;
                font-size: 14px;
                opacity: 0.8;
              }
              .title {
                font-family: $condensed-semibold-font-family;
                font-size: 20px;
                margin-right: 1em;
                margin-left: -1.5em;
                margin-bottom: 1.2em;
                display: inline-block;
              }
              .description-md {
                ul {
                  margin-left: 0;
                }
                ngx-md {
                  * {
                    font-family: $default-font-family !important;
                  }
                  p:first-child {
                    font-family: $condensed-semibold-font-family !important;
                    font-size: 20px;
                    margin-right: 1em;
                  }
                }
                margin-top: -3em;
              }
            }
          }
        }
      }
      .btn-group-actions {
        button {
          margin: 0.5em 1em;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .hol-row {
    display: flex;
    flex-direction: row;
    padding: 0 5px;
    > * {
      flex: 1;
    }
  }
  .hol-column {
    display: flex;
    flex-direction: column;
  }

  .label-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #796a6a;
    background-color: #f2f2f2;
    padding: 9px;
    font-weight: bold;
    color: #6a5f5f;
  }

  .info-attachment {
    color: grey;
    font-size: x-small;
    font-style: italic;
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
  }

  .right {
    justify-content: right;
    display: flex;
  }

  .scroll-container {
    overflow: auto;
    padding: 1em 0;
    @include scrollWithShadow(100%, 100%, 100%);
  }

  mat-form-field {
    width: 32%;
    input {
      font-family: $default-font-family;
    }
  }
}

.mat-form-field-appearance-fill .mat-form-field-underline {
  display: none;
}

.iscroll-scroller {
  overflow: auto;
  @include scrollWithShadow(100%, 100%, 100%);
}

.filters-panel {
  background-color: white;
  padding: $hol-content-padding;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: $hol-content-box;
  .filters-panel-section {
    padding: 22px $hol-item-margin 40px $hol-item-margin;
    h5 {
      margin-bottom: $hol-item-margin;
      font-size: 20px;
      font-family: $condensed-font-family;
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid $hol-light-grey;
    }
  }
}
