@use 'sass:math';
@import './mixins.scss';
@import './ui-kit/variables';

nav {
  background-color: $color_menu_background;
  text-transform: uppercase;
  color: $color_menu_font;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $nav_width;
  text-align: center;
  z-index: 20;
  font-family: $condensed-font-family;

  .hol-main-logo-div {
    background-color: white;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $border-color;
  }

  .hol-main-logo {
    max-width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
  }

  .crisis-background {
    width: $nav_width;

    &.crisis {
      background-color: $color_box_title_crisis;

      &.training {
        background-color: $color_exercise_active;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 10px 0;

      li {
        cursor: pointer;
        position: relative;
        min-height: 65px;
        margin: 4px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s;

        &.active {
          background-color: rgba($color_menu_background, 0.6);
        }

        &:hover {
          background-color: rgba($color_menu_background, 0.2);
        }

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
          cursor: no-drop;
        }

        a {
          color: $color_menu_font;
          height: 100%;

          &:hover,
          &:active,
          &:focus {
            text-decoration: none !important;

            * {
              text-decoration: none !important;
            }
          }

          .icon {
            display: block;
            font-size: 20px;
            font-style: normal;
            line-height: 1em;

            &.new-crisis {
              font-size: 40px;
              font-style: normal;
              line-height: 1em;
            }
          }

          .link {
            min-height: 14px;
            display: block;
            margin-top: 6px;
          }
        }
      }
    }
  }
}
