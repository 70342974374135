@font-face {
  font-family: 'holis-icon';
  src: url('/assets/fonts/holis-icon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='hol-icon-'],
[class*=' hol-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'holis-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hol-icon-refresh:before {
  content: '\e941';
}
.hol-icon-down:before {
  content: '\e900';
}
.hol-icon-left:before {
  content: '\e901';
}
.hol-icon-right:before {
  content: '\e902';
}
.hol-icon-up:before {
  content: '\e903';
}
.hol-icon-add-shopping-cart:before {
  content: '\e904';
}
.hol-icon-add:before {
  content: '\e905';
}
.hol-icon-airplane-mode-active:before {
  content: '\e906';
}
.hol-icon-align-center:before {
  content: '\e907';
}
.hol-icon-align-left:before {
  content: '\e908';
}
.hol-icon-align-right:before {
  content: '\e909';
}
.hol-icon-archive:before {
  content: '\e90a';
}
.hol-icon-breaking-news:before {
  content: '\e90b';
}
.hol-icon-calendar:before {
  content: '\e90c';
}
.hol-icon-cancel:before {
  content: '\e90d';
}
.hol-icon-chat:before {
  content: '\e90e';
}
.hol-icon-check-circle:before {
  content: '\e90f';
}
.hol-icon-circle:before {
  content: '\e910';
}
.hol-icon-close:before {
  content: '\e911';
}
.hol-icon-create-folder:before {
  content: '\e912';
}
.hol-icon-dashboard:before {
  content: '\e913';
}
.hol-icon-delete:before {
  content: '\e914';
}
.hol-icon-document:before {
  content: '\e915';
}
.hol-icon-done:before {
  content: '\e916';
}
.hol-icon-download:before {
  content: '\e917';
}
.hol-icon-drag:before {
  content: '\e918';
}
.hol-icon-duplicate:before {
  content: '\e919';
}
.hol-icon-edit:before {
  content: '\e91a';
}
.hol-icon-error:before {
  content: '\e91b';
}
.hol-icon-favorite:before {
  content: '\e91c';
}
.hol-icon-file:before {
  content: '\e91d';
}
.hol-icon-filter:before {
  content: '\e91e';
}
.hol-icon-fingerprint:before {
  content: '\e91f';
}
.hol-icon-flight-Land:before {
  content: '\e920';
}
.hol-icon-flight-takeoff:before {
  content: '\e921';
}
.hol-icon-folder:before {
  content: '\e922';
}
.hol-icon-fullscreen-exit:before {
  content: '\e923';
}
.hol-icon-fullscreen:before {
  content: '\e924';
}
.hol-icon-group:before {
  content: '\e925';
}
.hol-icon-history:before {
  content: '\e926';
}
.hol-icon-info:before {
  content: '\e927';
}
.hol-icon-link:before {
  content: '\e928';
}
.hol-icon-location-on:before {
  content: '\e929';
}
.hol-icon-mail:before {
  content: '\e92a';
}
.hol-icon-menu:before {
  content: '\e92b';
}
.hol-icon-more:before {
  content: '\e92c';
}
.hol-icon-notifications:before {
  content: '\e92d';
}
.hol-icon-open:before {
  content: '\e92e';
}
.hol-icon-picture:before {
  content: '\e92f';
}
.hol-icon-pin:before {
  content: '\e930';
}
.hol-icon-poll:before {
  content: '\e931';
}
.hol-icon-profile:before {
  content: '\e932';
}
.hol-icon-redo:before {
  content: '\e933';
}
.hol-icon-save:before {
  content: '\e934';
}
.hol-icon-search:before {
  content: '\e935';
}
.hol-icon-send:before {
  content: '\e936';
}
.hol-icon-share:before {
  content: '\e937';
}
.hol-icon-shopping-cart:before {
  content: '\e938';
}
.hol-icon-thumbs-up:before {
  content: '\e939';
}
.hol-icon-time:before {
  content: '\e93a';
}
.hol-icon-translate:before {
  content: '\e93b';
}
.hol-icon-tree:before {
  content: '\e93c';
}
.hol-icon-tune:before {
  content: '\e93d';
}
.hol-icon-visibility-off:before {
  content: '\e93e';
}
.hol-icon-visibility:before {
  content: '\e93f';
}
.hol-icon-whatshot:before {
  content: '\e940';
}
