@import './mixins.scss';

$indicator_width: 40px;
$item_list_height: 38px;
$font_size: 14px;

#page-team {
  .team-content {
    font-family: $condensed-font-family;
  }

  .hol-row {
    .team-box {
      .hol-box__header {
        .team-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .filters {
          z-index: 20;
          text-align: center;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: right;

            li {
              @include BoxShadowHelper(1);
              background-color: $color_disable_filter;
              color: #808080;
              font-weight: 600;
              font-size: 12px;

              &.active {
                color: white;
                background-color: $color_background_announcement;
              }

              &.ng-click-active {
                color: white;
                background-color: lighten($color_background_announcement, 10%);
              }
            }
          }
        }
      }

      .hol-box__content {
        .iscroll-wrapper {
          .iscroll-scroller {
            padding: 2px;
          }

          top: 30px;

          .tag {
            display: block;
            font-size: 14px !important;
            font-weight: bold;
            margin-bottom: 5px;
            padding: 5px;
            color: white;
          }

          .panel-group {
            margin: 0;
          }

          .function-panel {
            margin: 2px 0;
          }
        }

        .function-panel,
        .name-panel {
          &:not(.panel-open) {
            .fullTitle {
              color: #416794;
            }
          }

          &.panel-open {
            .team-line-countTODO {
              color: white;
            }
          }

          .spacing {
            margin-bottom: 5px;
          }

          .panel-heading {
            padding: 0;

            .chevron-block {
              width: 12px;
              margin-right: $content-padding;

              i {
                font-size: xx-small;
                font-weight: bold;

                &.icon-arrow_bottom {
                  font-size: 6px;
                }

                &.icon-add {
                  font-size: 16px;
                  font-weight: normal;
                }
              }
            }

            .functionTitle,
            .userTitle {
              font-family: $default-font-family;
              font-size: 0.84em;
              letter-spacing: -0.7px;
            }

            .functionTitle {
              font-weight: 500;
            }

            .userTitle {
              font-weight: initial;
            }

            .notify-btn {
              margin: -15px 0;
            }

            .functionShortTitle {
              transition: all 0.3s;
              margin-left: 10px;
              color: darken($color_short_info, 80%);

              &.not-low-key {
                color: $color_short_info;
                font-weight: bold;
              }
            }

            .functions {
              max-width: 50%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              line-height: 1.3em;
            }

            .nobody {
              color: $color_function_nobody;
            }

            .affected {
              color: $color_function_affected;
            }

            .not-authorized-func {
              color: $color_function_noauth;
            }

            .addself {
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
              background-color: #dedede;
              color: #808080;
              font-weight: 600;
            }

            .holder,
            .nobody,
            .affected {
              font-size: $font_size;
              text-align: right;
            }

            .text-muted {
              color: #aaaaaa;

              * {
                background-color: #dddddd;
              }

              .functionTitle {
                .fullTitle,
                .dash {
                  color: #aaaaaa;
                }
              }
            }
          }

          .panel-body {
            border: none;
            padding: 0;

            .team {
              $contact_width: 180px;
              $mailto_width: 50px;

              list-style: none;
              margin: 0;
              padding: 0;

              li {
                @include flex-row-center();
                color: $middle-blue;
                font-size: $font_size;
                position: relative;
                background: $team-ligther-blue;
                transition: background-color 0.2s ease-in;
                justify-content: space-between;
                padding: $content-padding;
                margin-top: 1px;

                &.ng-click-active,
                &:hover {
                  background-color: lighten($team-ligther-blue, 10%);
                }

                &.options {
                  justify-content: center;
                  background-color: transparent;
                  color: $text-color--light;
                  font-style: italic;
                }
              }
            }

            .actions {
              border-top: 1px solid white;
              background-color: $middle-blue;
              @include center-vertically();
              justify-content: space-around;
              height: 35px;

              .action {
                display: inline-block;
                width: 100%;
                height: 20px;

                button {
                  box-shadow: none;
                  border: none;
                  border-radius: 0;
                  background-color: transparent;
                  color: white;
                  height: 35px;
                  min-height: 35px;
                  line-height: 35px;
                  margin: -7px 0 0 0;
                  min-width: 0;
                  width: 100%;

                  md-icon {
                    width: 16px;
                    height: 16px;
                  }
                }

                &:not(:last-child) {
                  border-right: 1px solid white;
                }
              }
            }
          }

          transition: color 0.15s, background-color 0.15s;
        }

        .team-line,
        .function-line {
          @include flex-row-center();
          padding: 10px;
          width: 100%;

          &.team-line-head,
          &.function-line-head {
            padding: 0 $content-padding;
            font-size: 0.9em;
          }

          .line-title {
            flex: 2.4;
            @include flex-row-center();
          }

          .team-line-countTODO,
          .team-line-countFROZEN {
            width: 35px;
            text-align: center;
          }

          .team-line-countTODO {
            color: $color_todo;
          }

          .team-line-countFROZEN {
            color: $color_frozen;
            font-weight: bold;
          }

          .team-line-onDuty {
            flex: 1;
            text-align: right;
          }

          .team-line-functions {
            flex: 1;
            text-align: center;
          }

          .function-line-name {
            width: 50%;
          }

          .function-line-affectedFunctions {
            width: 50%;
            text-align: right;
          }

          &.has-no-tasks {
            background-color: #c9c9c9;
          }
        }
      }
    }

    .detail-col {
      height: 100%;
      padding-left: 8px;
      padding-right: 0px;

      .detail-col-wrapper {
        height: 100%;

        > div {
          height: 100%;
          padding-left: $content-padding;
          padding-right: 0;

          &:first-child {
            padding-left: 0;
          }
        }
      }

      .detail-item-loading {
        text-align: center;
      }

      .crisis-image-container {
        height: calc(100% / 3);

        .crisis-image {
          height: 100%;
          width: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .right-columns-container {
        height: calc(100% * 2 / 3);
        padding-top: $content-padding;

        .hol-box__content {
          .iscroll-wrapper {
            height: 100%;

            ul {
              list-style: none;
              margin: 0;
              padding: 0;

              li {
                @include flex-row-center();
                justify-content: space-between;
                height: $item_list_height;
                font-family: $default-font-family;
                font-size: $font_size;
                border-bottom: 1px solid #e7e7e7;
                padding: 0 $content-padding;

                .line-center {
                  flex: 1;

                  .name {
                    margin-right: 3px;
                  }
                }

                .line-right {
                  text-align: right;
                }
              }
            }
          }
        }
      }

      .tasks-box {
        .iscroll-wrapper {
          .iscroll-scroller {
            padding: 2px;
          }

          .tasks {
            margin-left: 0;
            margin-right: 0;
            border: 1px solid $border-color;
            border-bottom: none;
          }
        }
      }

      .members-box {
        .iscroll-wrapper {
          .iscroll-scroller {
            padding: 2px;
          }

          .name-panel {
            margin: 2px 0;
          }

          .funcUsers {
            margin-left: 0px;
            margin-right: 0px;
            list-style: none;
            padding: 0;

            .panel-heading {
              background-color: $middle-blue;
              color: white;
              @include flex-row-center();
              justify-content: space-between;
              padding: 10px;

              &.affected {
                color: $middle-blue;
                background-color: $team-ligther-blue;
              }

              &.not-authorized-func {
                color: $middle-blue;
                background-color: $color_function_noauth;
              }
            }

            .chevron-block {
              margin-right: $content-padding;

              .icon.icon-quit {
                transition: transform 300ms ease-in-out;
                font-size: 0.7em;
                font-weight: bold;
                display: inline-block;

                &.add {
                  transform: rotate(45deg);
                }

                &.remove {
                  transform: rotate(0deg);
                }

                @keyframes rotating {
                  from {
                    transform: rotate(0deg);
                  }
                  to {
                    transform: rotate(360deg);
                  }
                }

                &.working {
                  animation: rotating 1s ease-in-out infinite;
                }
              }

              .icon.icon-add {
                font-size: 0.7em;
                font-weight: bold;
                display: inline-block;
              }
            }

            .not-autorized {
              padding-right: 8px;

              .material-icons {
                margin-right: $content-padding !important;
                font-size: 6px;
                width: 12px;
                height: 12px;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

  .tasks {
    list-style: none;
    padding: 0;

    > li {
      border-bottom: 1px solid #e7e7e7;
      position: relative;
      background-color: white;
      transition: background-color 0.2s ease-in;
      transform-origin: 100% 0;
      font-family: $condensed-font-family;
      display: flex;
      align-items: center;

      &.hol-clickable {
        margin: 2px;
      }

      &.TODO.orange {
        .title {
          border-color: $color_orange;
        }

        .order {
          color: $color_orange;
        }
      }

      &.DONE {
        background-color: #e3fccf;

        .title {
          border-color: $color_done;
        }

        .order {
          color: $color_done;
        }
      }

      &.FROZEN {
        .title {
          border-color: $color_frozen;
        }

        .order {
          color: $color_frozen;
        }
      }

      &.NOT_APPLICABLE {
        background-color: $color_disable_filter;

        .title {
          border-color: $color_notApplicable;
        }

        .order,
        .title {
          color: $color_notApplicable;
        }
      }

      @keyframes highlight_anim {
        0% {
          opacity: 0.6;
        }
        100% {
          opacity: 0.9;
        }
      }

      &.highlight {
        animation: 0.3s highlight_anim infinite;
      }

      $order_width: 102px;

      .order {
        padding: $content-padding 5px;
        font-size: 28px;
        transition: color 0.5s ease-in;
        text-align: right;
        min-width: $order_width;
        max-width: $order_width;
        white-space: nowrap;
        overflow: hidden;
        height: 100%;

        .edit {
          font-size: 0.7em;
        }

        .label-short-title {
          font-size: 0.7em;
        }

        .label-order {
          font-size: 0.9em;
        }
      }

      .title {
        border-left: 6px solid $border-color;
        min-height: 60px;
        display: flex;
        transition: border-color 0.5s ease-in;

        @include taskTitleLink();
        padding: $content-padding; // 10px for safety
        color: black;
        font-weight: 400;
        vertical-align: middle;
        flex: 1;

        .title-wrapper {
          @include center-left-vertically();
          font-size: 1.2em;

          ul,
          ol {
            li {
              border: none;
              background-color: transparent;
            }
          }
        }
      }

      &.item-infiniscroll {
        text-align: center;
        border: none;
        padding: 20px;
        background-color: transparent;
        transition: all 0s;
      }
    }
  }
}

.createMemberModal,
.duplicateFunctionModal {
  .ng-invalid {
    border-color: $color_frozen !important;
  }

  .loading-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 50;
    background-color: $color_loading_overlay;

    @include center-vertically();
  }

  .explained,
  .error {
    font-style: normal;
    font-weight: 200;
    display: block;
    font-size: 1em;
    margin: 24px 0;
    text-align: center;

    &.error {
      color: red;
    }
  }

  .external-box {
    .help-block {
      margin-left: 40px;
      margin-top: -14px;
    }
  }
}

.team .name {
  .material-icons {
    margin-left: 5px;
    height: 25px;
    vertical-align: middle;
  }
}
