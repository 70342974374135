@import './mixins.scss';

.loadingContainer {
  position: absolute;
  height: 100%;
  width: 100%;

  @include center-vertically();
}

.full-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c8c8c8;
  z-index: 50;
  transition: opacity 0.5s;
  opacity: 0.8;
  &.ng-enter {
    opacity: 0;
    &.ng-enter-active {
      opacity: 0.8;
    }
  }
  &.ng-leave {
    opacity: 0.8;
    &.ng-leave-active {
      opacity: 0;
    }
  }
}
