@import '../crew/crew-variables';

@for $i from 1 through length($filters-colors) {
  $filter-colors: nth($filters-colors, $i);
  [filter-color],
  [appfiltercolor] {
    &.filter-color-#{$i - 1} {
      color: nth($filter-colors, 1);
      background-color: nth($filter-colors, 2);
    }
  }
}
