@import '../ui-kit/variables';

#page-crew-dashboard {
  app-ocl-global-instruction-box {
    height: 100%;
  }

  .acl-filter {
    display: flex;
    align-items: center;
  }

  .col-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    height: 100%;
    min-height: 100%;

    .custom-tabs {
      .tab-content {
        padding: 0 !important;
      }
    }

    .connected-users-container {
      display: block;
      flex: 1;
      margin-top: $content-padding;
      max-height: 170px;
    }

    .logs-container {
      display: block;
      flex: 1;
      margin-top: $content-padding;
    }

    .todos-container {
      display: block;
      flex: 1;

      &.responsive {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .indicators-container {
      background-color: #cecece;

      .hol-box__content {
        padding: 0;
      }
    }
  }

  .tabs-title {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1px;
    justify-content: space-between;
  }
}

.md-dialog-container md-dialog.modal-crew-choose-function {
  min-width: 50%;
  max-width: 50%;
  width: 50%;
}
