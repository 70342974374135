@import '../ui-kit/variables';
@import '../mixins';

.events-box {
  .hol-box__content {
    .event-item {
      padding: 0;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  a.external-document {
    float: none;
  }
}
