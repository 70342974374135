@use 'sass:math';
@import '../ui-kit/variables';

#page-crew-team {
  .col-container {
    display: flex;
    flex-direction: column;
    .teams-container {
      flex: 1;
      position: relative;
      overflow: auto;
      .hol-box:not(:last-child) {
        margin-right: $content-padding;
      }
      .team-col {
        min-width: 280px;
      }

      .user-item {
        margin: 2px 2px 5px 2px;
        .status-indicator {
          margin-right: math.div($content-padding, 2);
          margin-left: math.div($content-padding, 2);
          .icon {
            height: 25px;
            width: 25px;
            display: block;
            line-height: 25px;
            text-align: center;
          }
        }
        .panel-heading {
          padding: math.div($content-padding, 2);
          .panel-title {
            font-size: 14px;
            font-weight: normal;
            .chevron {
              font-size: xx-small;
              width: 12px;
              font-weight: bold;
              display: inline-block;
              text-align: center;
              &.icon-arrow_bottom {
                font-size: 6px;
              }
            }
          }
        }
        .panel-body {
          padding: $content-padding;
          .events-container {
            display: flex;
            flex-direction: row;
            margin: -5px;
            flex-wrap: wrap;
            .user-event {
              margin: 5px;
              .status-indicator {
                margin-left: 0;
              }
              display: inline-block;
              text-align: center;
              padding: 0 5px;
              .impact {
                color: $text-color;
              }
            }
          }
        }
      }
    }
  }
}
