@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clip:before {
  content: '\e92e';
  color: #fff;
}

.icon-picture:before {
  content: '\e92f';
  color: #fff;
}

.icon-icon_hotevent:before {
  content: '\e92a';
}

.icon-icon_incall:before {
  content: '\e92b';
}

.icon-icon_outcall:before {
  content: '\e92c';
}

.icon-icon_plane:before {
  content: '\e92d';
}

.icon-icon-people:before {
  content: '\e927';
}

.icon-aircraft-landing:before {
  content: '\e925';
}

.icon-aircraft-take-off:before {
  content: '\e926';
}

.icon-expand:before {
  content: '\e900';
}

.icon-arrow_bottom:before {
  content: '\e901';
}

.icon-arrow_right:before {
  content: '\e902';
}

.icon-arrow_top:before {
  content: '\e903';
}

.icon-check:before {
  content: '\e904';
}

.icon-checkbox_vide:before {
  content: '\e905';
}

.icon-checklist:before {
  content: '\e906';
}

.icon-confirm:before {
  content: '\e907';
}

.icon-dashboard:before {
  content: '\e908';
}

.icon-edit:before {
  content: '\e909';
}

.icon-epingle:before {
  content: '\e90a';
}

.icon-event_data:before {
  content: '\e90b';
}

.icon-facetime:before {
  content: '\e90c';
}

.icon-file:before {
  content: '\e90d';
}

.icon-freeze:before {
  content: '\e90e';
}

.icon-glass:before {
  content: '\e90f';
}

.icon-history:before {
  content: '\e910';
}

.icon-library:before {
  content: '\e911';
}

.icon-mail:before {
  content: '\e912';
}

.icon-map:before {
  content: '\e913';
}

.icon-megaphone:before {
  content: '\e914';
}

.icon-newspaper:before {
  content: '\e915';
}

.icon-note:before {
  content: '\e916';
}

.icon-phone:before {
  content: '\e917';
}

.icon-quit:before {
  content: '\e918';
}

.icon-lightning1:before {
  content: '\e919';
}

.icon-see_more:before {
  content: '\e91a';
}

.icon-settings:before {
  content: '\e91b';
}

.icon-team:before {
  content: '\e91c';
}

.icon-cancel-circle:before {
  content: '\e91d';
}

.icon-logout:before {
  content: '\e91e';
}

.icon-shutdown:before {
  content: '\e91f';
}

.icon-start:before {
  content: '\e920';
}

.icon-plus:before {
  content: '\e921';
}

.icon-arrow_select:before {
  content: '\e922';
}

.icon-reinstate:before {
  content: '\e923';
}

.icon-keyboard_arrow_right:before {
  content: '\e928';
}

.icon-add:before {
  content: '\e929';
}

.icon-alert:before {
  content: '\e924';
}

.icon-blocked:before {
  content: '\ea0e';
}

.icon-redo {
  padding: 1rem;
  background-image: url(/assets/images/redo-crew.svg);
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: center;
  background-size: 20px 20px;
  display: block;
}

.icon-world {
  padding: 1rem;
  background-image: url(/assets/images/world.svg);
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: center;
  background-size: 20px 20px;
  display: block;
}

.icon-link:before {
  content: '\e157';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
}
