@use 'sass:math';
@import '../ui-kit/variables';

#page-crew-checklist {
  .col-container {
    display: flex;
    flex-direction: column;
  }
  .events-container {
    height: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    display: flex;
    position: relative;
    .hol-warning {
      flex: 1;
    }
    .hol-box {
      width: 30%;
      flex-shrink: 0;
      margin-right: $content-padding;
      .hol-box__header {
        font-size: 14px;
        position: relative;
        .event-item {
          width: 100%;
        }
        .crew-event-item {
          width: calc(100% + 10px);
          overflow: hidden;
          white-space: normal;
          margin: -5px -5px -5px;
          padding-bottom: 15px;
        }
        .floating-btn {
          position: absolute;
          bottom: -25px;
          margin-left: 10px;
        }
      }
      .hol-box__content {
        margin-top: 25px;
        .occ-table .iscroll-wrapper > * > .hol-list > li {
          font-weight: normal;
        }
        .user-line {
          min-height: 25px;
        }
        .col-at {
          margin-right: $content-padding - 2px;
        }
        .created-by {
          margin-left: math.div($content-padding, 2);
        }
        .status-indicator {
          margin-left: $content-padding - 2px;
          .icon {
            height: 25px;
            width: 25px;
            display: block;
            line-height: 25px;
            text-align: center;
          }
        }
        .col-info {
          padding: 5px $content-padding 3px $content-padding;
          margin: -2px 2px 0 2px;
          background: $background-secondary;
          line-height: 1em;
        }
        .archive-btn {
          position: absolute;
          bottom: $content-padding;
          right: $content-padding;
        }
      }
    }
  }
}
